import { omit, prop } from 'ramda';

import { ID } from 'store/session';

import { wrapperRequest, getToken } from './utils';
import {
  API_URL, URL, POST, PUT, APPLICATION_TOKEN,
} from './consts';

export const signup = (body = {}) => wrapperRequest({
  url: `${URL}/signup`,
  options: {
    method: POST,
    headers: {
      'Content-Type': 'application/json',
      'api-key': APPLICATION_TOKEN,
    },
    body,
  },
});

export const login = (body = {}) => wrapperRequest({
  url: `${URL}/login`,
  options: {
    method: POST,
    headers: {
      'Content-Type': 'application/json',
      'api-key': APPLICATION_TOKEN,
    },
    body,
  },
});

export const restore = () => wrapperRequest({
  url: `${URL}/session`,
});

export const postUser = (body = {}) => wrapperRequest({
  url: `${API_URL}/users`,
  options: {
    method: POST,
    headers: {
      'Content-Type': 'application/json',
      'api-key': APPLICATION_TOKEN,
    },
    body,
  },
});

export const putUser = (body = {}) => wrapperRequest({
  url: `${API_URL}/users/${prop(ID, body)}`,
  options: { method: PUT, body: omit([ID], body) },
});

export const uploadFile = (file) => {
  const data = new FormData();
  data.append('file', file);

  return wrapperRequest({
    url: `${API_URL}/uploads`,
    options: {
      method: POST,
      headers: {
        AUTHORIZATION: `Bearer ${getToken()}`,
      },
      file: data,
    },
  });
};
