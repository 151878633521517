import React, { useState, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Message from 'components/Message';
import Spinner from 'components/Spinner';
import ConfirmMenu from 'components/ConfirmMenu';
import Delete from 'assets/svg/Delete';
import Pensile from 'assets/svg/Pensile';
import { useSearchesActions, useAlerts } from 'store/searches';

import {
  Wrapper, Texts, Title, Text, Panel, Button, SpinnerWrap,
} from './styles';

const Item = ({
  id, name, skills, education, experience, availabilities,
}) => {
  const [openMenu, setOpenMenu] = useState();
  const { extractId, error, loading } = useAlerts();
  const { push } = useHistory();
  const { removeSearch } = useSearchesActions();
  const { t } = useTranslation('search');

  const handleOpenDelete = useCallback(() => setOpenMenu(!openMenu), [openMenu]);

  const handleDelete = useCallback(() => {
    if (id) {
      extractId(removeSearch(id));
      setOpenMenu(false);
    }
  }, [id, extractId, removeSearch]);

  const redirectToEdit = useCallback(() => push(`/searches/${id}`), [push, id]);

  const redirectToRecommendations = useCallback(() => push(`/recommendations/${id}`), [push, id]);

  return (
    <div>
      <Wrapper>
        <Texts onClick={redirectToRecommendations}>
          <Title>{name}</Title>
          {skills && <Text>{skills}</Text>}
          {education && <Text>{education}</Text>}
          {experience && <Text>{experience}</Text>}
          {availabilities && <Text>{availabilities}</Text>}
        </Texts>
        <Panel>
          <Button type="button" disabled={loading} onClick={handleOpenDelete}>
            <Delete />
          </Button>
          <Button type="button" disabled={loading} onClick={redirectToEdit}>
            <Pensile />
          </Button>
        </Panel>
        <SpinnerWrap show={loading}>
          <Spinner />
        </SpinnerWrap>
      </Wrapper>
      {error && <Message error>{error}</Message>}
      <ConfirmMenu
        isOpen={openMenu}
        closeMenu={handleOpenDelete}
        action={handleDelete}
        question={t('Möchtest Du diese Suchanfrage wirklich löschen?')}
        positiveAnswer={t('Ja, löschen')}
        negativeAnswer={t('Nein, abbrechen')}
      />
    </div>
  );
};

Item.defaultProps = {
  skills: '-',
  availabilities: '-',
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  skills: PropTypes.string,
  education: PropTypes.string.isRequired,
  experience: PropTypes.string.isRequired,
  availabilities: PropTypes.string,
};

export default Item;
