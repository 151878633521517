import { useMemo, useCallback } from 'react';

import {
  ID, SKILLS, SKILLS_COMMENT, EDUCATION, EXPERIENCE,
  useCandidate, useCandidatesActions, useAlerts,
} from 'store/candidates';

const fields = [ID, SKILLS, SKILLS_COMMENT, EDUCATION, EXPERIENCE];

export const useInitialValues = () => {
  const candidate = useCandidate();

  return useMemo(() => fields.reduce((acc, field) => {
    acc[field] = (field === SKILLS) ? candidate[field] || [] : candidate[field] || '';
    return acc;
  }, {}), [candidate]);
};

export const useSubmitForm = () => {
  const alerts = useAlerts();
  const { saveCandidate } = useCandidatesActions();

  const onSubmit = useCallback((values) => {
    alerts.extractId(saveCandidate(values));
  }, [alerts, saveCandidate]);

  return [onSubmit, alerts];
};
