import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import ConfirmMenu from 'components/ConfirmMenu';
import Spinner from 'components/Spinner';
import Message from 'components/Message';
import { useExperiencesActions, useAlerts } from 'store/experiences';
import Delete from 'assets/svg/Delete';
import Pensile from 'assets/svg/Pensile';

import {
  Container, Text, Button, Texts, Panel, SpinnerWrap,
} from './styles';

const Experience = ({
  id, date, position, employer, interactive,
}) => {
  const { push } = useHistory();
  const { t } = useTranslation('experience');
  const [openMenu, setOpenMenu] = useState(false);
  const { extractId, loading, error } = useAlerts();
  const { removeExperience } = useExperiencesActions();

  const handleOpenMenu = useCallback(() => setOpenMenu(!openMenu), [openMenu]);

  const redirectToEdit = useCallback(() => {
    if (interactive) {
      push(`/experiences/${id}`);
    }
  }, [push, id, interactive]);

  const handleDelete = useCallback(() => {
    extractId(removeExperience(id));
    setOpenMenu(false);
  }, [removeExperience, id, extractId]);

  return (
    <>
      <Container interactive={interactive}>
        <Texts onClick={redirectToEdit} interactive={interactive}>
          <Text interactive={interactive}>{position}</Text>
          <Text interactive={interactive}>{employer}</Text>
          <Text interactive={interactive}>{date}</Text>
        </Texts>
        {interactive && (
          <Panel>
            <Button type="button" disabled={loading} onClick={handleOpenMenu}>
              <Delete />
            </Button>
            <Button type="button" disabled={loading} onClick={redirectToEdit}>
              <Pensile />
            </Button>
          </Panel>
        )}
        <SpinnerWrap show={loading}>
          <Spinner />
        </SpinnerWrap>
      </Container>
      {error && <Message error>{error}</Message>}
      <ConfirmMenu
        isOpen={openMenu}
        closeMenu={handleOpenMenu}
        action={handleDelete}
        question={t('Wollen Sie diesen Eintrag wirklich löschen?')}
        positiveAnswer={t('Ja, löschen')}
        negativeAnswer={t('Nein, abbrechen')}
      />
    </>
  );
};

Experience.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  position: PropTypes.string.isRequired,
  employer: PropTypes.string.isRequired,
  interactive: PropTypes.bool.isRequired,
};

export default Experience;
