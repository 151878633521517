import { path } from 'ramda';

import { FEATURE_NAME, LIST, RECOMMENDATIONS } from './consts';

export const getSearches = path([FEATURE_NAME, LIST]);
export const getSearch = (id) => path([FEATURE_NAME, LIST, id]);
export const getSearchProp = (id) => (name) => path([FEATURE_NAME, LIST, id, name]);

export const getRecommendations = path([FEATURE_NAME, RECOMMENDATIONS]);
export const getRecommendation = (id) => path([FEATURE_NAME, RECOMMENDATIONS, id]);
export const getRecommendationProp = (id) => (name) => (
  path([FEATURE_NAME, RECOMMENDATIONS, id, name])
);
