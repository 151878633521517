import { createAction } from 'redux-actions';

import {
  SET_LOADING,
  SET_SUCCESS,
  SET_ERROR,
  SET_CANCEL,
} from './types';

export const setLoading = createAction(SET_LOADING);
export const setSuccess = createAction(SET_SUCCESS);
export const setError = createAction(SET_ERROR);
export const setCancel = createAction(SET_CANCEL);
