import styled from 'styled-components';

import { fonts, colors } from 'theme';

export default styled.p`
  ${fonts.regular};
  color: ${({ success, error, simple }) => (success && colors.font.success)
    || (error && colors.font.error)
    || (simple && colors.font.gray)
    || 'none'};
  padding: ${({ padding }) => padding || 0};
`;
