import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import { prop } from 'ramda';

import { withAlert } from 'store/alerts';
// import { USER, sagaCan } from 'permissions';
import api from 'api';

import { ID, PROFILE_PHOTO } from './consts';
import { updateUser, setPermissions } from './actions';
import { getUserId } from './selectors';
import {
  SIGNUP,
  LOGIN,
  RESTORE,
  CREATE_USER,
  SAVE_USER,
  UPLOAD_USER_PHOTO,
} from './types';

function* signup({ payload }) {
  const data = yield call(api.signup, payload);

  if (data) {
    yield put(updateUser(data));
  }
}

function* login({ payload }) {
  const data = yield call(api.login, payload);
  const error = prop('error', data);

  if (!error) {
    yield put(setPermissions(data));
    yield put(updateUser(data));
  }

  return data;
}

function* restore() {
  const data = yield call(api.restore);
  yield put(setPermissions(data));
  yield put(updateUser(data));
}

function* createUser({ payload }) {
  const data = yield call(api.postUser, payload);
  const error = prop('error', data);

  if (!error) {
    yield put(updateUser(data));
  }

  return data;
}

function* saveUser({ payload }) {
  // yield sagaCan.update(USER);
  const data = yield call(api.putUser, payload);
  yield put(updateUser(data));
}

function* uploadUserPhoto({ payload }) {
  // yield sagaCan.update(USER);
  const { url } = yield call(api.uploadFile, payload);
  const userId = yield select(getUserId);
  const data = yield call(api.putUser, { [ID]: userId, [PROFILE_PHOTO]: url });
  yield put(updateUser(data));
}

export default function* () {
  yield takeLatest(SIGNUP, withAlert(signup));
  yield takeLatest(LOGIN, withAlert(login));
  yield takeLatest(RESTORE, withAlert(restore));
  yield takeLatest(CREATE_USER, withAlert(createUser));
  yield takeLatest(SAVE_USER, withAlert(saveUser));
  yield takeLatest(UPLOAD_USER_PHOTO, withAlert(uploadUserPhoto));
}
