export const DAY = 'day';
export const MONTH = 'month';
export const YEAR = 'year';
export const MAIN = 'main';

export const ERRORS = {
  [DAY]: 'Ungültiger Tag',
  [MONTH]: 'Ungültiger Monat',
  [YEAR]: 'Ungültiges Jahr',
  [MAIN]: 'Ungültiges Datum',
};
