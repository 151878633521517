/* eslint react/no-array-index-key: 0 */
import React from 'react';

import { useContent, IMPRINT } from 'content';

import {
  Title, Container, Box, P, ExternalLink,
} from './styles';

const Imprint = () => {
  const imprint = useContent(IMPRINT);

  return (
    <>
      <Title>Impressum</Title>
      {imprint.map((company, companyIndex) => (
        <Container key={companyIndex}>
          {company.map((list, index) => (
            <Box key={index}>
              {list.map((item, itemIndex) => (item.url
                ? (
                  <ExternalLink
                    key={itemIndex}
                    href={item.url}
                    target={item.target}
                  >
                    {item.name}
                  </ExternalLink>
                ) : (<P key={itemIndex}>{item.name}</P>)))}
            </Box>
          ))}
        </Container>
      ))}
    </>
  );
};

export default Imprint;
