import styled from 'styled-components';

import { metrics, colors, fonts } from 'theme';

export const Container = styled.div`
  width: 100%;
  height: ${({ error }) => (error ? 130 : 95)}px;
`;

export const Label = styled.p`
  ${fonts.regular}
  padding-bottom: 4px;
`;

export const Input = styled.input`
  width: 100%;
  height: 56px;
  border-radius: 11px;
  box-shadow: ${({ error }) => (error ? metrics.shadow.inputError : metrics.shadow.input)};
  background-color: ${colors.white};
  padding: 0 15px;
  ${fonts.regular}
`;
