import i18n from 'locale';
import {
  getContent, SKILLS_CONTENT, REGIONS_CONTENT, AVAILABILITIES_CONTENT,
} from 'content';

const getData = (name) => getContent(name, i18n.language);

const getLabels = (arr) => {
  let result = {};
  arr.forEach((a) => {
    result[a.key] = a.name;
    if (a.values.length) result = { ...result, ...getLabels(a.values) };
  });

  return result;
};

const createDescription = (keys, labels) => keys.reduce((acc, skill) => `${acc}${labels[skill]}, `, '').slice(0, -2);

const createNestedDescription = (selectedKeys, labels, data) => {
  const mainKeys = data.map((i) => i.key);
  const selectedMainKeys = mainKeys.filter((key) => selectedKeys.includes(key));
  return selectedMainKeys.reduce((acc, parent) => {
    const children = selectedKeys.filter((key) => key.includes(parent) && key !== parent);
    let childrenText = children.reduce((tmpText, key) => `${tmpText}${labels[key]}, `, '').slice(0, -2);
    if (childrenText) childrenText = ` (${childrenText})`;
    return `${acc}${labels[parent]}${childrenText}, `;
  }, '').slice(0, -2);
};

export function getSkills(selectedKeys) {
  const skillsData = getData(SKILLS_CONTENT);
  const result = [];
  const mainKeys = skillsData.map((i) => i.key);
  const selectedMainKeys = mainKeys.filter((key) => selectedKeys.includes(key));

  selectedMainKeys.forEach((mainKey) => {
    const mainItem = skillsData.filter((i) => i.key === mainKey)[0];
    const secondKeys = mainItem.values.map((i) => i.key);
    const selectedSecondKeys = secondKeys.filter((key) => selectedKeys.includes(key));
    if (!selectedSecondKeys.length) result.push(mainKey);
    else {
      selectedSecondKeys.forEach((secondKey) => {
        const secondItem = mainItem.values.filter((i) => i.key === secondKey)[0];
        const thirdKeys = secondItem.values.map((i) => i.key);
        const selectedThirdKeys = thirdKeys.filter((key) => selectedKeys.includes(key));
        if (!selectedThirdKeys.length) result.push(secondKey);
        else result.push(...selectedThirdKeys);
      });
    }
  });

  return createDescription(result, getLabels(skillsData));
}

export function getRegions(regions) {
  const regionsData = getData(REGIONS_CONTENT);
  const labels = getLabels(regionsData);
  return createNestedDescription(regions, labels, regionsData);
}

export function getAvailabilities(availabilities) {
  const availabilitiesData = getData(AVAILABILITIES_CONTENT);
  const labels = getLabels(availabilitiesData);
  return createNestedDescription(availabilities, labels, availabilitiesData);
}
