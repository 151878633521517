import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_CANDIDATE = getName('UPDATE_CANDIDATE');

export const CREATE_CANDIDATE = getName('CREATE_CANDIDATE');
export const FETCH_CANDIDATE = getName('FETCH_CANDIDATE');
export const SAVE_CANDIDATE = getName('SAVE_CANDIDATE');
