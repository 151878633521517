import { pick, prop, omit } from 'ramda';

import { ID as USER_ID, ROLES } from 'store/session';
import { ID } from 'store/candidates';

import { wrapperRequest } from './utils';
import { API_URL, POST, PUT } from './consts';

export const postCandidate = (body = {}) => wrapperRequest({
  url: `${API_URL}/candidates`,
  options: { method: POST, body: pick([USER_ID, ROLES], body) },
});

export const getCandidate = (id) => wrapperRequest({
  url: `${API_URL}/candidates/${id}`,
});

export const putCandidate = (body = {}) => wrapperRequest({
  url: `${API_URL}/candidates/${prop(ID, body)}`,
  options: { method: PUT, body: omit([ID], body) },
});
