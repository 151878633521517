import { createAction } from 'redux-actions';

import { creatAlertAction } from 'store/alerts';

import {
  UPDATE_EXPERIENCES,
  RESET_EXPERIENCES,
  UPDATE_EXPERIENCE,
  DELETE_EXPERIENCE,
  FETCH_EXPERIENCES,
  FETCH_EXPERIENCE,
  CREATE_EXPERIENCE,
  SAVE_EXPERIENCE,
  REMOVE_EXPERIENCE,
} from './types';

export const updateExperiences = createAction(UPDATE_EXPERIENCES);
export const resetExperiences = createAction(RESET_EXPERIENCES);
export const updateExperience = createAction(UPDATE_EXPERIENCE);
export const deleteExperience = createAction(DELETE_EXPERIENCE);

export const fetchExperiences = creatAlertAction(FETCH_EXPERIENCES);
export const fetchExperience = creatAlertAction(FETCH_EXPERIENCE);
export const createExperience = creatAlertAction(CREATE_EXPERIENCE);
export const saveExperience = creatAlertAction(SAVE_EXPERIENCE);
export const removeExperience = creatAlertAction(REMOVE_EXPERIENCE);
