import {
  ifElse, allPass, anyPass, is, pipe,
} from 'ramda';

import { LANG_LABELS } from 'locale/consts';
import i18n from 'locale';

export const formatDate = (d = 0) => ifElse(
  allPass([Boolean, anyPass([is(Number), is(String)])]),
  pipe((date) => new Date(date), i18n.formatDate.format),
  () => '',
)(d);

export const getLanguageLabel = (lang) => {
  const [{ name } = {}] = LANG_LABELS.filter((item) => item.id === lang);
  return name || '';
};
