import styled from 'styled-components';

import { colors, fonts, metrics } from 'theme';

const { active, inactive } = colors.switcher;

export const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Button = styled.button`
  position: relative;
  width: 50px;
  height: 30px;
  border-radius: 15px;
  background: ${({ checked }) => (checked ? active : inactive)};
  border: 1px solid ${({ checked }) => (checked ? active : inactive)};
  box-shadow: ${({ checked }) => (checked ? metrics.shadow.button : 'none')};
  transition: all .3s;
  &:disabled {
    opacity: .3;
  }
`;

export const Thumb = styled.div`
  position: absolute;
  top: 0;
  left: ${({ checked }) => (checked ? 'auto' : 0)};
  right: ${({ checked }) => (checked ? 0 : 'auto')};
  background: ${colors.white};
  height: 28px;
  width: 28px;
  border-radius: 14px;
  transition: all .3s;
  &>div {
    min-height: 26px;
  }
`;

export const Text = styled.p`${fonts.regular}`;
