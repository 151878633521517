import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Container, Label, Text } from './styles';

const Item = ({ label, text }) => {
  const { t } = useTranslation('form');

  return (
    <Container>
      <Label>{label}</Label>
      <Text>{text || t('Klicke auf Bearbeiten, um die fehlenden Infos zu ergänzen')}</Text>
    </Container>
  );
};

Item.defaultProps = {
  text: null,
};

Item.propTypes = {
  label: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};

export default Item;
