import {
  map, applySpec, prop, propOr, pipe,
} from 'ramda';

import { getSkills, getAvailabilities } from 'utils/contentHelpers';
import {
  ID, NAME, SKILLS, EDUCATION, EXPERIENCE, AVAILABILITIES,
} from 'store/searches';

export const handleList = map(applySpec({
  id: prop(ID),
  name: propOr('', NAME),
  skills: pipe(propOr([], SKILLS), getSkills),
  education: propOr('-', EDUCATION),
  experience: propOr('-', EXPERIENCE),
  availabilities: pipe(propOr([], AVAILABILITIES), getAvailabilities),
}));
