import { path, allPass } from 'ramda';

import {
  FEATURE_NAME as $,
  ID,
  AUTH,
  USER,
  PERMISSIONS,
} from './consts';

export const getAuth = path([$, AUTH]);
export const getUser = path([$, USER]);
export const getUserProp = (name) => path([$, USER, name]);
export const getUserId = path([$, USER, ID]);
export const getIsLoggedIn = allPass([getAuth, getUserId]);
export const getPermissions = path([$, PERMISSIONS]);
