import React, { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { isEmpty } from 'ramda';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import BackPanel from 'components/BackPanel';
import Input from 'components/FormInput';
import MultiLayerSelection from 'components/MultiLayerSelection';
import Message from 'components/Message';
import Spinner from 'components/Spinner';
import {
  NAME, SKILLS, EDUCATION, EXPERIENCE, AVAILABILITIES, REGIONS,
} from 'store/searches';
import {
  useContents,
  AVAILABILITIES_CONTENT, REGIONS_CONTENT, SKILLS_CONTENT, EDUCATIONS_CONTENT, EXPERIENCES_CONTENT,
} from 'content';

import { useInitialValues, useSubmitForm } from './hooks';
import { validationSchema, getError } from './validation';
import {
  Container, Select, Text, Button,
} from './styles';

const Search = () => {
  const { id } = useParams();
  const initialValues = useInitialValues(id);
  const [onSubmit, {
    loading, error, resetAlerts,
  }] = useSubmitForm(id);
  const handleSubmitClick = useCallback(() => resetAlerts(), [resetAlerts]);
  const { t } = useTranslation(['search', 'form']);

  const [
    availabilities, regions, skills, educations, experiences,
  ] = useContents([
    AVAILABILITIES_CONTENT,
    REGIONS_CONTENT,
    SKILLS_CONTENT,
    EDUCATIONS_CONTENT,
    EXPERIENCES_CONTENT,
  ]);

  const {
    values, handleSubmit, getFieldProps, setFieldValue, errors,
  } = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
    onSubmit,
  });

  const updateSkills = useCallback((val) => setFieldValue(SKILLS, val), [setFieldValue]);
  const updateAvailabilities = useCallback(
    (val) => setFieldValue(AVAILABILITIES, val),
    [setFieldValue],
  );
  const updateRegions = useCallback((val) => setFieldValue(REGIONS, val), [setFieldValue]);

  return (
    <Container onSubmit={handleSubmit}>
      <BackPanel title={values[NAME] || t('Neue Suche')} to="/searches" />
      <Input label={t('Name der Suchanfrage')} {...getFieldProps(NAME)} error={getError(errors[NAME])} />
      <Select
        label={t('Höchster Ausbildungsabschluss')}
        options={educations}
        {...getFieldProps(EDUCATION)}
      />
      <Text>{t('Fähigkeiten')}</Text>
      <MultiLayerSelection
        data={skills}
        selected={values[SKILLS]}
        onUpdate={updateSkills}
      />
      <Text>{t('Verfügbare Regionen')}</Text>
      <MultiLayerSelection
        data={regions}
        selected={values[REGIONS]}
        onUpdate={updateRegions}
      />
      <Select
        label={t('Erfahrung')}
        options={experiences}
        {...getFieldProps(EXPERIENCE)}
      />
      <Text>{t('Verfügbare Wochentage und Zeiten')}</Text>
      <MultiLayerSelection
        data={availabilities}
        selected={values[AVAILABILITIES]}
        onUpdate={updateAvailabilities}
      />
      <Button
        type="submit"
        color="blue"
        disabled={loading}
        onClick={handleSubmitClick}
      >
        {loading ? <Spinner size={40} /> : t('Jetzt Finden')}
      </Button>
      {(error || !isEmpty(errors)) && <Message error>{error || t('form:Wir haben einen Fehler in deinen Angaben gefunden. Bitte überprüfe deine Angaben.')}</Message>}
    </Container>
  );
};

export default Search;
