import React from 'react';
import PropTypes from 'prop-types';

import { Container, Wrapper, Dot } from './styles';

const Progress = ({
  steps, current, navigate,
}) => {
  const moveTo = ((index) => {
    if (index < current) navigate(index);
  });

  return (
    <Container>
      <Wrapper amount={steps.length}>
        {steps.map((step) => (
          <Dot key={step} isActive={step <= current} onClick={() => moveTo(step)} />
        ))}
      </Wrapper>
    </Container>
  );
};

Progress.propTypes = {
  current: PropTypes.number.isRequired,
  navigate: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(PropTypes.number.isRequired).isRequired,
};

export default Progress;
