import { handleActions } from 'redux-actions';
import { prop, pipe, propOr } from 'ramda';

import { TOKEN } from 'api/consts';
import { extractPermissions } from 'permissions';

import { LOGOUT, UPDATE_USER, SET_PERMISSIONS } from './types';
import {
  AUTH, USER, PERMISSIONS, ROLES,
} from './consts';

const getInit = () => ({
  [AUTH]: Boolean(prop(TOKEN, window.localStorage)),
  [USER]: {},
  [PERMISSIONS]: {},
});

export default handleActions({
  [LOGOUT]: () => {
    window.localStorage.removeItem(TOKEN);
    window.location.replace('/');

    return getInit();
  },
  [UPDATE_USER]: (state, { payload }) => {
    const token = prop(TOKEN, payload);

    if (token) {
      window.localStorage.setItem(TOKEN, token);
    }

    return {
      ...state,
      [AUTH]: token ? Boolean(token) : state[AUTH],
      [USER]: payload,
    };
  },
  [SET_PERMISSIONS]: (state, { payload }) => ({
    ...state,
    [PERMISSIONS]: pipe(propOr([], ROLES), extractPermissions)(payload),
  }),
}, getInit());
