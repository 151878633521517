import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { values } from 'ramda';

import { wrapActions, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { usePermission, EXPERIENCE } from 'permissions';

import * as actions from './actions';
import {
  getExperiences,
  getExperience,
  getExperienceProp,
} from './selectors';

export const useExperiencesActions = wrapActions({ ...actions });

export const useExperiences = () => {
  const experiences = useSelector(getExperiences);

  return useMemo(() => values(experiences), [experiences]);
};

export const useExperience = (id, arg) => (
  wrapMultiSelector(getExperienceProp(id), getExperience(id))(arg)
);

export const useInitExperiences = () => {
  const can = usePermission();
  const hasAccess = can.read(EXPERIENCE) && can.update(EXPERIENCE);
  const alerts = useAlerts();
  const { extractId, id } = alerts;
  const { fetchExperiences } = useExperiencesActions();

  useEffect(() => {
    if (hasAccess && !id) {
      extractId(fetchExperiences());
    }
  }, [hasAccess, id, extractId, fetchExperiences]);

  return hasAccess ? alerts : { success: true };
};

export const useInitExperience = (experienceId) => {
  const can = usePermission();
  const hasAccess = can.read(EXPERIENCE) && can.update(EXPERIENCE);
  const alerts = useAlerts();
  const { extractId, id } = alerts;
  const { fetchExperience } = useExperiencesActions();

  useEffect(() => {
    if (hasAccess && !id) {
      extractId(fetchExperience(experienceId));
    }
  }, [id, hasAccess, extractId, fetchExperience, experienceId]);

  return hasAccess ? alerts : { success: true };
};
