import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import './locale';
import ReactGA from 'react-ga';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ThemeProvider } from 'styled-components';

import store from 'store/store';
import theme from 'theme';
import Root from 'components/Root';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Router>
        <Root />
      </Router>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-58346232-12');
  ReactGA.set({ anonymizeIp: true });
  ReactGA.pageview(window.location.pathname);
}
