import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_USER = getName('UPDATE_USER');
export const LOGOUT = getName('LOGOUT');
export const SET_PERMISSIONS = getName('SET_PERMISSIONS');

export const SIGNUP = getName('SIGNUP');
export const LOGIN = getName('LOGIN');
export const RESTORE = getName('RESTORE');
export const CREATE_USER = getName('CREATE_USER');
export const SAVE_USER = getName('SAVE_USER');
export const UPLOAD_USER_PHOTO = getName('UPLOAD_USER_PHOTO');
