import React from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BackPanel from 'components/BackPanel';
import Message from 'components/Message';
import Spinner from 'components/Spinner';
import CandidateList from 'components/CandidateList';
import {
  NAME, SKILLS, EDUCATION, EXPERIENCE, AVAILABILITIES,
  useSearch, useRecommendations, useInitRecommendations,
} from 'store/searches';
import { getSkills, getAvailabilities } from 'utils/contentHelpers';
import { useMatches } from 'store/matches';

import {
  Container, Wrapper, Title, Text,
} from './styles';

const Recommendations = () => {
  const { id: searchId } = useParams();
  const [
    name, skills, education, experience, availabilities,
  ] = useSearch(searchId, [
    NAME, SKILLS, EDUCATION, EXPERIENCE, AVAILABILITIES,
  ]);
  const { loading, error, id } = useInitRecommendations(searchId);
  const recommendations = useRecommendations() || [];
  const matches = useMatches() || [];
  const { t } = useTranslation('search');

  if (error) return <Message error>{error}</Message>;
  if (!id || loading) return <Spinner height="calc(100vh - 265px)" />;

  return (
    <Container>
      <BackPanel title={t('Ergebnisse')} to="/searches" />
      <Wrapper>
        <Title>{name || t('Neue Suche')}</Title>
        {getSkills(skills) && <Text>{getSkills(skills)}</Text>}
        {education && <Text>{education}</Text>}
        {experience && <Text>{experience}</Text>}
        {getAvailabilities(availabilities) && (
          <Text>{getAvailabilities(availabilities)}</Text>
        )}
      </Wrapper>
      <CandidateList items={recommendations} matches={matches} type="recommendations" />
    </Container>
  );
};

export default Recommendations;
