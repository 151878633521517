import styled from 'styled-components';

import { colors, fonts } from 'theme';

export const Container = styled.div`
  width: 100%;
  padding-bottom: 40px;
  border-top: ${({ withoutBorder }) => (withoutBorder ? 'none' : `1px solid ${colors.border.gray}`)};
`;

export const Title = styled.p`
  padding: 10px 0 20px 0;
  ${fonts.title}
`;

export const Wrapper = styled.div`
  width: 100%;
`;
