import React, { useCallback } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { all, allPass } from 'ramda';
import PropTypes from 'prop-types';

import { usePermission } from 'permissions';
import { useSessionActions } from 'store/session';

const PrivateRoute = ({ component, sources, ...rest }) => {
  const can = usePermission();
  const { logout } = useSessionActions();

  const getComponent = useCallback((props) => {
    const Component = component;
    if (all(allPass([can.read, can.update]), sources)) return <Component {...props} />;

    logout();

    return <Redirect to="/register" />;
  }, [component, can, logout, sources]);

  return (
    <Route render={getComponent} {...rest} />
  );
};

PrivateRoute.propTypes = {
  sources: PropTypes.arrayOf(PropTypes.string).isRequired,
  component: PropTypes.func.isRequired,
};

export default PrivateRoute;
