import { useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { values } from 'ramda';

import { wrapActions, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { usePermission, SEARCH } from 'permissions';

import * as actions from './actions';
import {
  getSearches, getSearch, getSearchProp,
  getRecommendations, getRecommendation, getRecommendationProp,
} from './selectors';

export const useSearchesActions = wrapActions({ ...actions });

export const useSearches = () => {
  const searches = useSelector(getSearches);
  return useMemo(() => values(searches), [searches]);
};

export const useSearch = (id, arg) => wrapMultiSelector(getSearchProp(id), getSearch(id))(arg);

export const useRecommendations = () => {
  const recommendations = useSelector(getRecommendations);
  return useMemo(() => values(recommendations), [recommendations]);
};

export const useRecommendation = (id, arg) => (
  wrapMultiSelector(getRecommendationProp(id), getRecommendation(id))(arg)
);

export const useInitSearches = () => {
  const can = usePermission();
  const hasAccess = can.read(SEARCH);
  const alerts = useAlerts();
  const { extractId, id } = alerts;
  const { fetchSearches } = useSearchesActions();

  useEffect(() => {
    if (hasAccess && !id) {
      extractId(fetchSearches());
    }
  }, [hasAccess, id, extractId, fetchSearches]);

  return hasAccess ? alerts : { success: true };
};

export const useInitSearch = (searchId) => {
  const can = usePermission();
  const hasAccess = can.read(SEARCH);
  const alerts = useAlerts();
  const { extractId, id } = alerts;
  const { fetchSearch } = useSearchesActions();

  useEffect(() => {
    if (hasAccess && !id) {
      extractId(fetchSearch(searchId));
    }
  }, [id, hasAccess, extractId, fetchSearch, searchId]);

  return hasAccess ? alerts : { success: true };
};

export const useInitRecommendations = (searchId) => {
  const can = usePermission();
  const hasAccess = can.read(SEARCH) && can.update(SEARCH);
  const alerts = useAlerts();
  const { extractId, id } = alerts;
  const { fetchRecommendations, resetRecommendations } = useSearchesActions();

  useEffect(() => {
    if (hasAccess && !id) {
      extractId(fetchRecommendations(searchId));
    }
  }, [id, hasAccess, extractId, fetchRecommendations, searchId]);

  useEffect(() => () => {
    resetRecommendations();
  }, [resetRecommendations]);

  return hasAccess ? alerts : { success: true };
};
