import posed from 'react-pose';
import styled from 'styled-components';

import { colors, metrics, fonts } from 'theme';

export const Container = styled.div`
  flex: 1 1 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  min-height: 250px;
  width: 100%;
  overflow: hidden;
`;

export const Wrapper = styled(posed.div({
  hideLeft: {
    x: '-110%',
    opacity: 0,
  },
  hideRight: {
    x: '110%',
    opacity: 0,
  },
  show: {
    x: '0%',
    opacity: 1,
  },
}))`
  position: absolute;
  width: 100%;
`;

export const Label = styled.p`
  ${fonts.title}
  padding: 0 0 15px 20px;
`;

export const Input = styled.input`
  width: 100%;
  height: 60px;
  border-radius: 20px;
  box-shadow: ${({ error }) => (error ? metrics.shadow.inputError : metrics.shadow.input)};
  background-color: ${colors.white};
  padding: 0 25px;
  ${fonts.regular}
`;
