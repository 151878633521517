import { createGlobalStyle } from 'styled-components';

import { metrics, colors } from 'theme';

export default createGlobalStyle`
  @font-face {
    font-family: HKGrotesk-Bold;
    src: url(/fonts/HKGrotesk-Bold.woff) format("woff"), url(/fonts/HKGrotesk-Bold.woff2) format("woff2");
  }
  @font-face {
    font-family: HKGrotesk-Medium;
    src: url(/fonts/HKGrotesk-Medium.woff) format("woff"), url(/fonts/HKGrotesk-Medium.woff2) format("woff2");
  }
  @font-face {
    font-family: HKGrotesk-SemiBold;
    src: url(/fonts/HKGrotesk-SemiBold.woff) format("woff"), url(/fonts/HKGrotesk-SemiBold.woff2) format("woff2");
  }
  html, body, body>div {
    height: 1px;
    min-height: 100%;
    width: 100%;
    min-width: ${metrics.minWidth}px;
    overflow-scrolling: touch;
    -webkit-overflow-scrolling: touch;
  }
  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: "HKGrotesk-Medium", sans-serif;
    font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-tap-highlight-color: transparent;
  }
  input, textarea {
    appearance: none;
    -webkit-appearance: none;
    background: none;
    border: none;
    outline: none;
  }
  button {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
    textarea {
      border: 1px solid ${colors.gray} !important;
      overflow: auto;
    }
    p {
      max-width: 100%;
    }
  }
`;
