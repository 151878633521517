export const FEATURE_NAME = 'institutions';

export const ID = '_id';
export const CURRENT = 'current';
export const FAVORITES = 'favorites';

export const NAME = 'name';
export const ADDRESS = 'address';
export const ZIP_CODE = 'zipCode';
export const CITY = 'city';
export const PHONE = 'phone';
export const LOGO = 'logo';
