import styled from 'styled-components';

import { fonts, colors } from 'theme';

export const Introduction = styled.div`
  margin: 30px 0 60px 0;
`;

export const Title = styled.h1`
  ${fonts.title}
  margin: 8px 0;
`;

export const P = styled.div`
  ${fonts.regular};
  margin-bottom: 30px;
  max-width: 800px;
`;

export const StyledLink = styled.a`
  ${fonts.regular}
  text-decoration: underline;

  &:hover {
    color: ${colors.font.blue};
  }
`;
