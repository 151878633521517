import styled from 'styled-components';

import { fonts, colors } from 'theme';

export const Container = styled.div`
    max-width: 630px;
    padding-bottom: 30px;
`;

export const SubTitle = styled.h2`
  ${fonts.regular};
  margin: 1em 0 0;
`;

export const P = styled.p`
  ${fonts.regular};
  margin: ${({ marginTop }) => (marginTop ? ' 1em 0 0' : 0)};
`;

export const ListItem = styled.p`
  ${fonts.regular}
  margin: 0;
`;

export const Title = styled.h1`
  ${fonts.title};
  margin: 30px 0;
  padding-bottom: 30px;
  border-bottom: solid 1px ${colors.gray};
`;
