import { reducerTemplate } from 'store/utils';

import { ID } from './consts';
import {
  UPDATE_EXPERIENCES,
  RESET_EXPERIENCES,
  UPDATE_EXPERIENCE,
  DELETE_EXPERIENCE,
} from './types';

export default reducerTemplate({
  id: ID,
  list: UPDATE_EXPERIENCES,
  item: UPDATE_EXPERIENCE,
  deleteItem: DELETE_EXPERIENCE,
  reset: RESET_EXPERIENCES,
}, {});
