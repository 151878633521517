import {
  useMemo, useCallback, useEffect,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { omit } from 'ramda';

import {
  ID, EMPLOYER, POSITION, START, END,
  useExperience, useExperiencesActions, useAlerts,
} from 'store/experiences';

const fields = [ID, EMPLOYER, POSITION, START, END];

export const useInitialValues = () => {
  const { id } = useParams();
  const experience = useExperience(id) || {};

  return useMemo(() => fields.reduce((acc, field) => {
    acc[field] = experience[field] || '';

    return acc;
  }, { isStartDateError: '', isEndDateError: '' }), [experience]);
};

export const useSubmitForm = () => {
  const { push } = useHistory();
  const alerts = useAlerts();
  const { saveExperience } = useExperiencesActions();

  const onSubmit = useCallback((values) => {
    alerts.extractId(saveExperience(omit(['isStartDateError', 'isEndDateError'], values)));
  }, [alerts, saveExperience]);

  useEffect(() => {
    if (alerts.success) {
      push('/experiences');
    }
  }, [push, alerts]);

  return [onSubmit, alerts];
};
