import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_INSTITUTION = getName('UPDATE_INSTITUTION');
export const UPDATE_FAVORITES = getName('UPDATE_FAVORITES');
export const RESET_FAVORITES = getName('RESET_FAVORITES');

export const FETCH_INSTITUTION = getName('FETCH_INSTITUTION');
export const SAVE_INSTITUTION = getName('SAVE_INSTITUTION');
export const UPLOAD_INSTITUTION_PHOTO = getName('UPLOAD_INSTITUTION_PHOTO');
export const FETCH_FAVORITES = getName('FETCH_FAVORITES');
