import { prop, omit } from 'ramda';

import { ID } from 'store/matches';

import { wrapperRequest } from './utils';
import { API_URL, POST, PUT } from './consts';

export const postMatch = (body = {}) => wrapperRequest({
  url: `${API_URL}/matches`,
  options: { method: POST, body },
});

export const getMatches = () => wrapperRequest({
  url: `${API_URL}/matches`,
});

export const getMatch = (matchId) => wrapperRequest({
  url: `${API_URL}/matches/${matchId}`,
});

export const putMatch = (body = {}) => wrapperRequest({
  url: `${API_URL}/matches/${prop(ID, body)}`,
  options: { method: PUT, body: omit([ID], body) },
});
