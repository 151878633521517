import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CreateButton from 'components/CreateButton';
import { NEW_SEARCH, useSearches } from 'store/searches';

import Item from './Item';
import { handleList } from './utils';
import { Container, Msg } from './styles';

const Searches = () => {
  const { push } = useHistory();
  const searches = useSearches();
  const { t } = useTranslation('search');
  const list = useMemo(() => handleList(searches), [searches]);

  const redirectToNewSearch = useCallback(() => push(`/searches/${NEW_SEARCH}`), [push]);

  return (
    <Container>
      <CreateButton title={t('Neue Suche')} onClick={redirectToNewSearch} />
      {!list.length && <Msg>{t('Erstelle jetzt deine erste Suche, indem du auf «Neue Suche» klickst. Sobald du eine Suche ausgeführt hast, werden wir deine vergangenen Suchen hier anzeigen.')}</Msg>}
      {list.map((item) => <Item key={item.id} {...item} />)}
    </Container>
  );
};

export default Searches;
