import React from 'react';
import PropTypes from 'prop-types';

import {
  Menu, Question, Yes, No,
} from './styles';

const ConfirmMenu = ({
  isOpen, closeMenu, action, question, positiveAnswer, negativeAnswer,
}) => (
  <Menu pose={isOpen ? 'open' : 'close'}>
    <Question>{question}</Question>
    <Yes type="button" onClick={action}>{positiveAnswer}</Yes>
    <No type="button" onClick={closeMenu}>{negativeAnswer}</No>
  </Menu>
);

ConfirmMenu.defaultProps = {
  isOpen: false,
  closeMenu: () => null,
  action: () => null,
};

ConfirmMenu.propTypes = {
  isOpen: PropTypes.bool,
  closeMenu: PropTypes.func,
  action: PropTypes.func,
  question: PropTypes.string.isRequired,
  positiveAnswer: PropTypes.string.isRequired,
  negativeAnswer: PropTypes.string.isRequired,
};

export default ConfirmMenu;
