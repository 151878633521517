import { createAction } from 'redux-actions';

import { creatAlertAction } from 'store/alerts';

import {
  UPDATE_MATCHES,
  UPDATE_MATCH,
  CREATE_MATCH,
  FETCH_MATCHES,
  FETCH_MATCH,
  SAVE_MATCH,
} from './types';

export const updateMatches = createAction(UPDATE_MATCHES);
export const updateMatch = createAction(UPDATE_MATCH);

export const createMatch = creatAlertAction(CREATE_MATCH);
export const fetchMatches = creatAlertAction(FETCH_MATCHES);
export const fetchMatch = creatAlertAction(FETCH_MATCH);
export const saveMatch = creatAlertAction(SAVE_MATCH);
