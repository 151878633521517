import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Section from 'components/Section';
import Item from 'components/FormTextItem';
import ExperienceList from 'components/ExperienceList';
import RedirectBtn from 'components/RedirectBtn';
import ProfilePhoto from 'components/ProfilePhoto';
import Message from 'components/Message';
import {
  ID, STATUS, FIRST_NAME, LAST_NAME, EMAIL, PROFILE_PHOTO, ZIP_CODE, CITY, MOBILE, BIRTH,
  useUser, useSessionActions, useAlerts, LANGUAGE,
} from 'store/session';
import {
  useCandidate, EXPERIENCE, AVAILABLE_FROM, SKILLS,
  SKILLS_COMMENT, AVAILABILITIES, HOURS_PER_WEEK, EDUCATION, REGIONS,
} from 'store/candidates';
import { useExperiences } from 'store/experiences';
import { getSkills, getRegions, getAvailabilities } from 'utils/contentHelpers';
import { CANDIDATE_STATUSES } from 'utils/consts';
import { formatDate, getLanguageLabel } from 'utils';
import { useContents, EXPERIENCES_CONTENT, EDUCATIONS_CONTENT } from 'content';

import Switcher from './Switcher';
import Grade from './Grade';
import { estimateProfile, getLabel } from './utils';
import { Container, Wrapper, Text } from './styles';

const { ACTIVE, INACTIVE } = CANDIDATE_STATUSES;

const Candidate = () => {
  const [
    id,
    status,
    firstName,
    lastName,
    email,
    profilePhoto,
    zipCode,
    city,
    mobile,
    birthday,
    language,
  ] = useUser([
    ID,
    STATUS,
    FIRST_NAME,
    LAST_NAME,
    EMAIL,
    PROFILE_PHOTO,
    ZIP_CODE,
    CITY,
    MOBILE,
    BIRTH,
    LANGUAGE,
  ]);
  const { extractId, loading, error } = useAlerts();
  const { uploadUserPhoto, saveUser } = useSessionActions();
  const [
    experience,
    skills = [],
    skillsComment,
    availableFrom,
    hoursPerWeek,
    availabilities = [],
    education,
    regions = [],
  ] = useCandidate([
    EXPERIENCE,
    SKILLS,
    SKILLS_COMMENT,
    AVAILABLE_FROM,
    HOURS_PER_WEEK,
    AVAILABILITIES,
    EDUCATION,
    REGIONS,
  ]);
  const experiences = useExperiences();
  const [
    experiencesContent, educationsContent,
  ] = useContents([
    EXPERIENCES_CONTENT, EDUCATIONS_CONTENT,
  ]);

  const { t } = useTranslation(['profile', 'skill', 'availability']);

  const handleStatus = useCallback(() => {
    extractId(saveUser({ [ID]: id, [STATUS]: status === ACTIVE ? INACTIVE : ACTIVE }));
  }, [extractId, saveUser, id, status]);

  const grade = estimateProfile([
    firstName,
    lastName,
    email,
    zipCode,
    city,
    birthday,
    mobile,
    profilePhoto,
    experiences,
    skills,
    education,
    experience,
    regions,
    availabilities,
    availableFrom,
    hoursPerWeek,
  ]);

  const educationTitle = useMemo(
    () => getLabel(education, educationsContent),
    [education, educationsContent],
  );
  const experienceTitle = useMemo(
    () => getLabel(experience, experiencesContent),
    [experience, experiencesContent],
  );

  return (
    <Container>
      <Wrapper>
        <ProfilePhoto url={profilePhoto} action={uploadUserPhoto} interactive />
        <Grade grade={grade} />
      </Wrapper>
      <Section title={`${firstName} ${lastName}`} withoutBorder />
      <Section>
        <Switcher label={t('Mein Profil ist aktiv')} onClick={handleStatus} checked={status === ACTIVE} disabled={loading} />
        {error && <Message error>{error}</Message>}
      </Section>
      <Section title={t('Persönliche Angaben')}>
        <Item label={t('Name')} text={`${firstName} ${lastName}`} />
        <Item label={t('E-Mail')} text={email} />
        <Item label={t('PLZ')} text={zipCode} />
        <Item label={t('Ort')} text={city} />
        <Item label={t('Mobile')} text={mobile} />
        <Item label={t('Geburtsdatum')} text={formatDate(birthday)} />
        <Item label={t('Sprache')} text={getLanguageLabel(language)} />
        <RedirectBtn to="/personal" />
      </Section>
      <Section title={t('skill:Fähigkeiten')}>
        <Item label={t('skill:Hintergrund')} text={getSkills(skills)} />
        <Text>{skillsComment}</Text>
        <Item label={t('skill:Höchster Ausbildungsabschluss')} text={educationTitle} />
        <Item label={t('skill:Anzahl Jahre Berufserfahrung')} text={experienceTitle} />
        <RedirectBtn to="/skills" />
      </Section>
      <Section title={t('skill:Berufserfahrung')}>
        <ExperienceList list={experiences} />
        <RedirectBtn to="/experiences" />
      </Section>
      <Section title={t('availability:Verfügbarkeit')}>
        <Item label={t('availability:Region')} text={getRegions(regions)} />
        <Item label={t('availability:Mögliches Startdatum')} text={formatDate(availableFrom)} />
        <Item label={t('availability:Max. Anzahl Stunden / Woche')} text={hoursPerWeek} />
        <Item label={t('availability:Verfügbare Wochentage')} text={getAvailabilities(availabilities)} />
        <RedirectBtn to="/availability" />
      </Section>
    </Container>
  );
};

export default Candidate;
