import styled from 'styled-components';

import DefSelect from 'components/Select';
import DefButton from 'components/Button';
import { fonts } from 'theme';

export const Container = styled.form`
  margin-bottom: 40px;
`;

export const Select = styled(DefSelect)`
  margin-top: 30px;
`;

export const Text = styled.p`
  ${fonts.regular}
  margin: 25px 0;
`;

export const Button = styled(DefButton)`
  margin-top: 40px;
  margin-bottom: 25px;
`;
