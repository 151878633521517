import styled from 'styled-components';
import posed from 'react-pose';

import { colors } from 'theme';

const { blue } = colors.background;

const transition = {
  type: 'keyframes',
  times: [0, 0.5, 1],
  duration: 300,
};

const style = `
  position: absolute;
  bottom: 4px;
  width: 16px;
  height: 2px;
`;

export const Container = styled.div`
  position: relative;
  width: 22px;
  height: 16px;
`;

export const LeftLine = styled(posed.div({
  close: {
    transform: 'rotate(45deg)',
    left: -2,
    borderRadius: 0,
    transition: {
      transform: ({ from, to }) => ({
        ...transition,
        values: [from, 'rotate(90deg)', to],
      }),
      left: () => ({
        ...transition,
        values: [-2, 3, -2],
      }),
      borderRadius: ({ from }) => ({
        ...transition,
        values: [from, 1, from],
      }),
    },
  },
  open: {
    transform: 'rotate(135deg)',
    left: -2,
    borderRadius: 0,
    transition: {
      transform: ({ from, to }) => ({
        ...transition,
        values: [from, 'rotate(90deg)', to],
      }),
      left: () => ({
        ...transition,
        values: [-2, 3, -2],
      }),
      borderRadius: ({ from }) => ({
        ...transition,
        values: [from, 1, from],
      }),
    },
  },
}))`
  ${style}
  background: ${blue};
`;

export const RightLine = styled(posed.div({
  close: {
    transform: 'rotate(135deg)',
    left: 8,
    borderRadius: 0,
    transition: {
      transform: ({ from, to }) => ({
        ...transition,
        values: [from, 'rotate(90deg)', to],
      }),
      left: () => ({
        ...transition,
        values: [8, 3, 8],
      }),
      borderRadius: ({ from }) => ({
        ...transition,
        values: [from, 1, from],
      }),
    },
  },
  open: {
    transform: 'rotate(45deg)',
    left: 8,
    borderRadius: 0,
    transition: {
      transform: ({ from, to }) => ({
        ...transition,
        values: [from, 'rotate(90deg)', to],
      }),
      left: () => ({
        ...transition,
        values: [8, 3, 8],
      }),
      borderRadius: ({ from }) => ({
        ...transition,
        values: [from, 1, from],
      }),
    },
  },
}))`
  ${style}
  background: ${blue};
`;
