import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CreateButton from 'components/CreateButton';
import ExperienceList from 'components/ExperienceList';
import BackPanel from 'components/BackPanel';
import { NEW_EXPERIENCE, useExperiences } from 'store/experiences';

import { Container, Message } from './styles';

const Experiences = () => {
  const { push } = useHistory();
  const experiences = useExperiences();
  const { t } = useTranslation('experience');

  const redirectToNewExperience = useCallback(() => push(`/experiences/${NEW_EXPERIENCE}`), [push]);

  return (
    <Container>
      <BackPanel title={t('Berufserfahrung')} to="/profile" />
      <CreateButton title={t('Neuer Eintrag')} onClick={redirectToNewExperience} />
      {experiences.length ? (
        <ExperienceList list={experiences} interactive />
      ) : (
        <Message>
          {t('Erfasse deine Berufserfahrung, indem Du auf «Neuer Eintrag» klickst.')}
        </Message>
      )}
    </Container>
  );
};

export default Experiences;
