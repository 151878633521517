import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { isEmpty } from 'ramda';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BackPanel from 'components/BackPanel';
import MultiLayerSelection from 'components/MultiLayerSelection';
import Section from 'components/Section';
import Select from 'components/Select';
import Button from 'components/Button';
import Message from 'components/Message';
import Spinner from 'components/Spinner';
import {
  SKILLS, SKILLS_COMMENT, EDUCATION, EXPERIENCE,
} from 'store/candidates';
import {
  useContents,
  SKILLS_CONTENT, EDUCATIONS_CONTENT, EXPERIENCES_CONTENT,
} from 'content';

import { useInitialValues, useSubmitForm } from './hooks';
import { validationSchema } from './validation';
import {
  Container, Wrapper, MessageWrap, Textarea,
} from './styles';

const Skills = () => {
  const { push } = useHistory();

  const { t } = useTranslation(['skill', 'form']);
  const [
    skills, educations, experiences,
  ] = useContents([
    SKILLS_CONTENT, EDUCATIONS_CONTENT, EXPERIENCES_CONTENT,
  ]);

  const [onSubmit, {
    success, loading, error, resetAlerts,
  }] = useSubmitForm();
  const initialValues = useInitialValues();
  const handleSubmitClick = useCallback(() => resetAlerts(), [resetAlerts]);
  const {
    values, handleSubmit, getFieldProps, setFieldValue, errors,
  } = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
    onSubmit,
  });
  const redirectToProfile = useCallback(() => push('/profile'), [push]);

  const updateSkills = useCallback((val) => setFieldValue(SKILLS, val), [setFieldValue]);

  return (
    <Container onSubmit={handleSubmit}>
      <BackPanel title={t('Fähigkeiten')} to="/profile" />
      <Section title={t('Qualifikationen')}>
        <MultiLayerSelection data={skills} selected={values[SKILLS]} onUpdate={updateSkills} />
        <Textarea placeholder={t('Weitere Tätigkeitsfelder')} {...getFieldProps(SKILLS_COMMENT)} rows={8} />
      </Section>
      <Section title={t('Berufserfahrung')}>
        <Select
          label={t('Höchster Ausbildungsabschluss im gewählten Gebiet')}
          options={educations}
          {...getFieldProps(EDUCATION)}
          error={errors[EDUCATION]}
        />
        <Select
          label={t('Anzahl Jahre Berufserfahrung')}
          options={experiences}
          {...getFieldProps(EXPERIENCE)}
          error={errors[EXPERIENCE]}
        />
      </Section>
      <Wrapper>
        <Button type="button" onClick={redirectToProfile}>{t('form:Abbrechen')}</Button>
        <Button
          type="submit"
          color="blue"
          disabled={loading}
          onClick={handleSubmitClick}
        >
          {loading ? <Spinner size={40} /> : t('form:Speichern')}
        </Button>
        <MessageWrap>
          {(error || !isEmpty(errors)) && (
            <Message error>
              {error || t('form:Wir haben einen Fehler in deinen Angaben gefunden. Bitte überprüfe deine Angaben.')}
            </Message>
          )}
          {success && (
          <Message success>
            {t('form:Vielen Dank für deine Angaben. Gesundheitsinstitutionen können ab sofort auf dein Profil zugreifen und dich für einen Einsatz anfragen. Die Anfragen erhältst du per E-Mail.')}
          </Message>
          )}
        </MessageWrap>
      </Wrapper>
    </Container>
  );
};

export default Skills;
