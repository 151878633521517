import {
  takeEvery, takeLatest, call, put,
} from 'redux-saga/effects';
import { prop, omit } from 'ramda';

import api from 'api';
import { withAlert } from 'store/alerts';

import { ID } from './consts';
import {
  updateExperiences,
  updateExperience,
  deleteExperience,
} from './actions';
import {
  FETCH_EXPERIENCES,
  FETCH_EXPERIENCE,
  CREATE_EXPERIENCE,
  SAVE_EXPERIENCE,
  REMOVE_EXPERIENCE,
} from './types';

function* fetchExperiences() {
  const data = yield call(api.getExperiences);
  yield put(updateExperiences(data));
}

function* fetchExperience({ payload }) {
  const data = yield call(api.getExperience, payload);
  yield put(updateExperience(data));
}

function* createExperience({ payload }) {
  const data = yield call(api.postExperience, payload);
  yield put(updateExperience(data));
}

function* saveExperience({ payload }) {
  if (prop(ID, payload)) {
    const data = yield call(api.putExperience, payload);
    yield put(updateExperience(data));
  } else {
    yield createExperience({ payload: omit([ID], payload) });
  }
}

function* removeExperience({ payload }) {
  const data = yield call(api.deleteExperience, payload);
  yield put(deleteExperience(data));
}

export default function* () {
  yield takeLatest(FETCH_EXPERIENCES, withAlert(fetchExperiences));
  yield takeLatest(FETCH_EXPERIENCE, withAlert(fetchExperience));
  yield takeEvery(CREATE_EXPERIENCE, withAlert(createExperience));
  yield takeEvery(SAVE_EXPERIENCE, withAlert(saveExperience));
  yield takeEvery(REMOVE_EXPERIENCE, withAlert(removeExperience));
}
