import styled from 'styled-components';

import { metrics, fonts, colors } from 'theme';
import { Link } from 'react-router-dom';

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: ${metrics.header.height}px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
  min-width: ${metrics.minWidth}px;
  z-index: 10;
`;

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: ${metrics.maxWidth}px;
  margin: auto 15px;
  border-bottom: 1px solid;
`;

export const Label = styled(Link)`
  ${fonts.title};
  color: ${colors.font.blue};
  text-decoration: none;
  display: block;
`;

export const ButtonWrapper = styled.div`
  position: relative;
  height: 100%;
`;
