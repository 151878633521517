/* eslint-disable global-require, import/no-dynamic-require */
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { path, map } from 'ramda';

import { LANG_LIST } from 'locale/consts';

import { CONTENT_LIST } from './consts';

const contents = {};

CONTENT_LIST.forEach((content) => {
  contents[content] = {};
  LANG_LIST.forEach((lng) => {
    contents[content][lng] = require(`./${content}/${lng}.json`);
  });
});

export const getContent = (content, lng) => path([content, lng], contents);

export const useContent = (name) => {
  const { i18n } = useTranslation();

  return useMemo(() => getContent(name, i18n.language), [name, i18n.language]);
};

export const useContents = map(useContent);

export * from './consts';
