import React, { useCallback, useEffect, useMemo } from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  prop, cond, not, equals,
} from 'ramda';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import Spinner from 'components/Spinner';
import { INSTITUTION, CANDIDATE } from 'permissions';
import {
  ID, FIRST_NAME, LAST_NAME, EMAIL, PASSWORD, ROLES, INSTITUTION_NAME, LANGUAGE,
  useAuth, useSessionActions, useAlerts, useUser,
} from 'store/session';

import Carousel from './Carousel';
import Progress from './Progress';
import { Container } from './styles';
import { validationSchema } from './validation';

const Register = () => {
  const { state = {} } = useLocation();
  const isAuth = useAuth();
  const [userId, roles] = useUser([ID, ROLES]);
  const { signup, createUser, login } = useSessionActions();
  const signupAlerts = useAlerts();
  const createUserAlerts = useAlerts();
  const loginAlerts = useAlerts();
  const { t, i18n } = useTranslation('register');

  const onSubmit = useCallback((values, { setFieldValue }) => {
    if (values.step === 1) {
      signupAlerts.extractId(signup({
        [EMAIL]: values.email.trim(),
      }));
    }
    if (values.step === 2) {
      setFieldValue('step', 3);
    }
    if (values.step === 3 && !values.email) {
      setFieldValue('step', 4);
    }
    if (values.step === 3 && values.email) {
      const {
        name, role, email, institution,
      } = values;
      const names = name.split(' ');

      createUserAlerts.extractId(createUser({
        [INSTITUTION_NAME]: institution,
        [FIRST_NAME]: names[0].trim(),
        [LAST_NAME]: (names[1] || '').trim(),
        [EMAIL]: email.trim(),
        [ROLES]: [role],
        [LANGUAGE]: i18n.language,
      }));
    }

    if (values.step === 4) {
      const { email, password } = values;

      loginAlerts.extractId(login({
        [EMAIL]: email.trim(),
        [PASSWORD]: password,
      }));
    }
  }, [createUser, createUserAlerts, signupAlerts, login, loginAlerts, signup, i18n]);

  const {
    values,
    handleSubmit,
    getFieldProps,
    getFieldHelpers,
    setFieldValue,
    setFieldError,
    errors,
  } = useFormik({
    initialValues: {
      step: 1,
      role: prop('role', state),
      askRegistration: '',
      institution: '',
      name: '',
      email: '',
      password: '',
    },
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
    onSubmit,
  });

  useEffect(() => {
    if (values.step === 1 && signupAlerts.success && userId) {
      setFieldValue('step', 4);
    }
    if (values.step === 1 && signupAlerts.success && !userId && !values.role) {
      setFieldError('email', t('Es existiert kein Account mit dieser E-Mail-Adresse. Bitte gehe zur Startseite und registriere dich, indem du auf «Jetzt Hilfe anbieten» oder «Jetzt Hilfe suchen» klickst.'));
    }
    if (values.step === 1 && signupAlerts.success && !userId && values.role) {
      setFieldValue('step', values.role === INSTITUTION ? 2 : 3);
    }
    if (values.step === 1 && signupAlerts.error) {
      setFieldError('email', signupAlerts.error);
    }

    if (values.step === 3 && userId) {
      setFieldValue('step', 4);
    }

    if (values.step === 4 && loginAlerts.error) {
      setFieldError('password', loginAlerts.error);
    }
  }, [
    userId,
    signupAlerts.success,
    signupAlerts.error,
    values.role,
    values.step,
    createUserAlerts.success,
    createUserAlerts.error,
    loginAlerts.error,
    setFieldValue,
    setFieldError,
    t,
  ]);

  const steps = useMemo(() => cond([
    [not, () => [1, 4]],
    [equals(CANDIDATE), () => [1, 3, 4]],
    [equals(INSTITUTION), () => [1, 2, 3, 4]],
  ])(values.role), [values.role]);

  if (isAuth && roles.includes(INSTITUTION)) return <Redirect to="/searches" />;

  if (isAuth) return <Redirect to="/profile" />;

  return (
    <Container onSubmit={handleSubmit}>
      <Carousel
        getFieldProps={getFieldProps}
        errors={errors}
        getFieldHelpers={getFieldHelpers}
      />
      <Progress
        steps={steps}
        current={values.step}
        navigate={getFieldHelpers('step').setValue}
      />
      <Button
        type="submit"
        color="blue"
        disabled={
          (values.step === 2 && !values.institution)
          || (values.step === 3 && !values.name)
          || createUserAlerts.loading
          || loginAlerts.loading
          || signupAlerts.loading
        }
      >
        {createUserAlerts.loading || loginAlerts.loading || signupAlerts.loading ? <Spinner size={40} /> : t('Weiter')}
      </Button>
    </Container>
  );
};

export default Register;
