import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import {
  Container, Wrapper, First, Second, Third, Center, P,
} from './styles';


const Grade = ({ grade }) => {
  const percentages = Math.round(grade * 100);
  const { t } = useTranslation('profile');

  return (
    <Container>
      <Wrapper>
        <First ratio={grade} />
        <Second ratio={grade} />
        <Third ratio={grade} />
        <Center />
      </Wrapper>
      <P>{`${t('Profil zu')} ${percentages}% ${t('vollständig')}`}</P>
    </Container>
  );
};

Grade.propTypes = {
  grade: PropTypes.number.isRequired,
};

export default Grade;
