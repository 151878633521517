import styled from 'styled-components';

import { fonts } from 'theme';

export const Container = styled.form`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 60px 0 50px 0;
  &>* {
    margin-bottom: 20px;
  }
`;

export const MessageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; 
`;

export const Text = styled.p`
  ${fonts.regular}
  margin: 20px 0 10px 0;
`;
