import styled from 'styled-components';
import posed from 'react-pose';

const AnimateLayer = posed.div({
  open: {
    height: 'auto',
    bottom: 0,
  },
  close: {
    height: 0,
    bottom: 'auto',
  },
});

const AnimateLayer2 = posed.div({
  open: {
    height: 'auto',
    bottom: 0,
  },
  close: {
    height: 0,
    bottom: 'auto',
  },
});

export const SecondLayer = styled(AnimateLayer)`
  overflow: hidden;

  & > *:last-child {
    margin-bottom: 30px;
  }
`;

export const ThirdLayer = styled(AnimateLayer2)`
  /* display: flex;
  flex-wrap: wrap; */
  overflow: hidden;
  margin-bottom: 30px;
`;
