import { handleActions } from 'redux-actions';

import { CURRENT, FAVORITES } from './consts';
import { UPDATE_INSTITUTION, UPDATE_FAVORITES, RESET_FAVORITES } from './types';

const init = {
  [CURRENT]: null,
  [FAVORITES]: [],
};

export default handleActions({
  [UPDATE_INSTITUTION]: (state, { payload }) => ({
    ...state,
    [CURRENT]: payload || null,
  }),
  [UPDATE_FAVORITES]: (state, { payload }) => ({
    ...state,
    [FAVORITES]: payload || [],
  }),
  [RESET_FAVORITES]: (state) => ({
    ...state,
    [FAVORITES]: [],
  }),
}, init);
