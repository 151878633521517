import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  pipe, map, prop, values,
} from 'ramda';
import PropTypes from 'prop-types';

import { LANG_LABELS } from 'locale/consts';
import { usePermission, USER } from 'permissions';
import {
  useUser, useSessionActions, ID, LANGUAGE,
} from 'store/session';

import { Lang } from './styles';

const Language = ({ id, name }) => {
  const { i18n } = useTranslation();
  const can = usePermission();
  const [userId, lang] = useUser([ID, LANGUAGE]);
  const { saveUser } = useSessionActions();

  const handleClick = useCallback(() => {
    i18n.changeLanguage(id);
    if (id !== lang && can.update(USER)) {
      saveUser({ [ID]: userId, [LANGUAGE]: id });
    }
  }, [i18n, id, can, saveUser, lang, userId]);

  return (
    <Lang
      type="button"
      active={id === i18n.language}
      disabled={id === i18n.language}
      onClick={handleClick}
    >
      {name}
    </Lang>
  );
};

Language.propTypes = {
  id: PropTypes.oneOf(
    pipe(map(prop('id')), values)(LANG_LABELS),
  ).isRequired,
  name: PropTypes.string.isRequired,
};

export default Language;
