export const FEATURE_NAME = 'alerts';

export const SUCCESS = 'success';
export const ERROR = 'error';
export const LOADING = 'loading';

export const META = 'meta';
export const TASK_ID = 'taskId';

export const DEFAULT_ERROR = 'Oops, ein Fehler ist aufgetreten. Hilf uns, dein Benutzererlebnis zu verbessern und sende uns einen Fehlerbericht.';
