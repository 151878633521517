import React, { useMemo, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import { CANDIDATE_STATUSES } from 'utils/consts';
import {
  ID, FIRST_NAME, LAST_NAME, PROFILE_PHOTO,
} from 'store/session';
import {
  SKILLS, SKILLS_COMMENT, EDUCATION, REGIONS, EXPERIENCE,
  AVAILABLE_FROM, HOURS_PER_WEEK, AVAILABILITIES, AVAILABILITY_COMMENT,
} from 'store/candidates';
import { CANDIDATE, EXPERIENCES } from 'store/searches';

import Item from './Item';
import { handleList, getEmptyListMessage } from './utils';
import {
  Label, Message, Menu, MenuBtn,
} from './styles';

const CandidateList = ({ matches, items, type }) => {
  const { t } = useTranslation('search');
  const [filter, setFilter] = useState();

  const setAll = useCallback(() => setFilter(''), []);
  const setMarker = useCallback(() => setFilter(CANDIDATE_STATUSES.ACTIVE), []);
  const setContact = useCallback(() => setFilter(CANDIDATE_STATUSES.INACTIVE), []);

  const list = useMemo(() => (
    handleList(matches, filter)(items, matches)
  ), [items, matches, filter]);

  const emptyListMessage = useMemo(() => getEmptyListMessage(type)(filter), [filter, type]);

  return (
    <>
      <Menu>
        <MenuBtn type="button" active={!filter} onClick={setAll}>{t('Alle')}</MenuBtn>
        <MenuBtn type="button" active={filter === CANDIDATE_STATUSES.ACTIVE} onClick={setMarker}>{t('Markiert')}</MenuBtn>
        <MenuBtn type="button" active={filter === CANDIDATE_STATUSES.INACTIVE} onClick={setContact}>{t('Kontaktiert')}</MenuBtn>
      </Menu>
      {list.length ? (
        <>
          <Label>{t('Helfer*innen')}</Label>
          {list.map((item) => <Item key={item.id} {...item} />)}
        </>
      ) : (
        <Message>{t(emptyListMessage)}</Message>
      )}
    </>
  );
};

CandidateList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    [ID]: PropTypes.string.isRequired,
    [FIRST_NAME]: PropTypes.string,
    [LAST_NAME]: PropTypes.string,
    [PROFILE_PHOTO]: PropTypes.string,
    [EXPERIENCES]: PropTypes.arrayOf(PropTypes.object),
    [CANDIDATE]: PropTypes.shape({
      [SKILLS]: PropTypes.arrayOf(PropTypes.string),
      [SKILLS_COMMENT]: PropTypes.string,
      [EXPERIENCE]: PropTypes.string,
      [EDUCATION]: PropTypes.string,
      [REGIONS]: PropTypes.arrayOf(PropTypes.string),
      [AVAILABLE_FROM]: PropTypes.string,
      [HOURS_PER_WEEK]: PropTypes.number,
      [AVAILABILITIES]: PropTypes.arrayOf(PropTypes.string),
      [AVAILABILITY_COMMENT]: PropTypes.string,
    }),
  })).isRequired,
  matches: PropTypes.arrayOf(
    PropTypes.shape({
      user: PropTypes.string.isRequired,
    }),
  ).isRequired,
  type: PropTypes.oneOf(['favorites', 'recommendations']).isRequired,
};

export default CandidateList;
