import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_MATCHES = getName('UPDATE_MATCHES');
export const UPDATE_MATCH = getName('UPDATE_MATCH');

export const CREATE_MATCH = getName('CREATE_MATCH');
export const FETCH_MATCHES = getName('FETCH_MATCHES');
export const FETCH_MATCH = getName('FETCH_MATCH');
export const SAVE_MATCH = getName('SAVE_MATCH');
