import styled from 'styled-components';

import { fonts, colors } from 'theme';

export const Container = styled.div`
  max-width: 480px;
  margin-bottom: 80px;
`;

export const Title = styled.h1`
  ${fonts.title};
  margin: 30px 0;
  padding-bottom: 30px;
  border-bottom: solid 1px ${colors.gray};
`;

export const Box = styled.div`
  margin-bottom: 1em;
`;

export const ExternalLink = styled.a`
  ${fonts.regular};
  text-decoration: none;
  display: block;
  margin: 0;
  transition: color 0.5s;

  &:hover {
    color: ${colors.font.blue};
  }
`;

export const P = styled.p`
  ${fonts.regular};
  margin: 0;
`;
