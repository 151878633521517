import { object, string } from 'yup';
import i18n from 'locale';

import {
  EMPLOYER, POSITION, START, END,
} from 'store/experiences';

export const validationSchema = object().shape({
  [EMPLOYER]: string().required('Ungültiger Wert'),
  [POSITION]: string().required('Ungültiger Wert'),
  [START]: string().notRequired(),
  [END]: string().notRequired(),
  isStartDateError: string().notRequired(),
  isEndDateError: string().notRequired(),
});

export const getError = (error) => error && i18n.t(`errors:${error}`);
