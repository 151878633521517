export const CREATE = 'canCreate';
export const READ = 'canRead';
export const UPDATE = 'canUpdate';
export const DELETE = 'canDelete';

export const CANDIDATE = 'CANDIDATE';
export const INSTITUTION = 'INSTITUTION';

export const USER = 'USER';
export const EXPERIENCE = 'EXPERIENCE';
export const SEARCH = 'SEARCH';
export const MATCH = 'MATCH';
