import React from 'react';
import PropTypes from 'prop-types';

import { useInitSession } from 'store/session';
import { useInitCandidate } from 'store/candidates';
import { useInitExperiences } from 'store/experiences';
import { useInitSearches } from 'store/searches';
import { useInitInstitution } from 'store/institutions';
import { useInitMatches } from 'store/matches';
import { mergeAlerts } from 'store/alerts';
import Spinner from 'components/Spinner';
import Message from 'components/Message';

const InitApp = ({ children }) => {
  const sessionAlerts = useInitSession();
  const candidateAlerts = useInitCandidate();
  const experiencesAlerts = useInitExperiences();
  const searchesAlerts = useInitSearches();
  const institutionsAlerts = useInitInstitution();
  const matchesAlerts = useInitMatches();

  const {
    success, loading, error,
  } = mergeAlerts([
    sessionAlerts,
    candidateAlerts,
    experiencesAlerts,
    searchesAlerts,
    institutionsAlerts,
    matchesAlerts,
  ]);

  if (error) return <Message error>{error}</Message>;

  if (!(success || error) || loading) return <Spinner height="calc(100vh - 265px)" />;

  return children;
};

InitApp.propTypes = {
  children: PropTypes.element.isRequired,
};

export default InitApp;
