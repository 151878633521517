/* eslint react/no-array-index-key: 0 */

import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useContent, PRIVACY } from 'content';
import {
  Title, Container, SubTitle, P, ListItem,
} from './styles';


const Privacy = () => {
  const { t } = useTranslation('privacy');

  const content = useContent(PRIVACY);

  const hasListItems = useCallback((l) => l && Boolean(l.filter((item) => item).length), []);

  return (
    <>
      <Title>{t('Datenschutz')}</Title>
      <Container>
        <P>{content.description}</P>
        {content.items.map((item, index) => (
          <div key={item.id}>
            {item.title && <SubTitle>{`${index + 1}. ${item.title}`}</SubTitle>}
            <P marginTop={!item.title}>{item.text}</P>
            {hasListItems(item.list) && item.list.map(((listitem, listindex) => (
              <ListItem key={listindex}>
                {listitem.bulletpoint && <span>{`${listitem.bulletpoint} `}</span>}
                {listitem.text}
              </ListItem>
            )))}
          </div>
        ))}
        <P marginTop>{content.version}</P>
      </Container>
    </>
  );
};

export default Privacy;
