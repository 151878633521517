/* eslint-disable global-require, import/no-dynamic-require */
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import detector from 'i18next-browser-languagedetector';
import {
  cond, T, pipe, take, __, includes,
} from 'ramda';

import { DEFAULT_LANGUAGE, LANG_LIST, LOCALE_LIST } from './consts';

const resources = {};

LANG_LIST.forEach((lng) => {
  if (!resources[lng]) {
    resources[lng] = {};
  }
  LOCALE_LIST.forEach((locale) => {
    resources[lng][locale] = require(`./${locale}/${lng}`);
  });
});

const changeDateFormatter = () => {
  i18n.formatDate = new Intl.DateTimeFormat(i18n.language, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
};

const checkLanguage = () => {
  cond([
    [includes(__, LANG_LIST), T],
    [pipe(take(2), includes(__, LANG_LIST)), pipe(take(2), i18n.changeLanguage.bind(i18n))],
    [T, () => i18n.changeLanguage(DEFAULT_LANGUAGE)],
  ])(i18n.language);

  changeDateFormatter();
};

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources,
    fallbackLng: DEFAULT_LANGUAGE,
    debug: process.env.NODE_ENV === 'development',
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  }, checkLanguage);

i18n.on('languageChanged', changeDateFormatter);

export default i18n;
