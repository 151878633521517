import {
  CREATE,
  READ,
  UPDATE,
  DELETE,
  CANDIDATE,
  INSTITUTION,
  USER,
  EXPERIENCE,
  SEARCH,
  MATCH,
} from './consts';

export default {
  [CANDIDATE]: {
    [USER]: {
      [READ]: true,
      [UPDATE]: true,
    },
    [CANDIDATE]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
    },
    [EXPERIENCE]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [DELETE]: true,
    },
  },
  [INSTITUTION]: {
    [USER]: {
      [READ]: true,
      [UPDATE]: true,
    },
    [INSTITUTION]: {
      [READ]: true,
      [UPDATE]: true,
    },
    [CANDIDATE]: {
      [READ]: true,
    },
    [EXPERIENCE]: {
      [READ]: true,
    },
    [SEARCH]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
      [DELETE]: true,
    },
    [MATCH]: {
      [CREATE]: true,
      [READ]: true,
      [UPDATE]: true,
    },
  },
};
