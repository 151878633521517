import styled from 'styled-components';
import posed from 'react-pose';

import { colors, fonts } from 'theme';

const { background, font } = colors;

export const Container = styled(
  posed.div({
    open: { height: 'auto' },
    close: { height: 80 },
  }),
)`
  overflow: hidden;
  margin-bottom: 15px;
  border: 1px solid ${background.blue};
  border-radius: 11px;
  padding: 0 16px 16px;
`;

export const HeadWrap = styled.button`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;
`;

export const TextWrap = styled.div`
  margin-top: 15px;
`;

export const Experience = styled.div`
  margin-bottom: 15px;
`;

export const Panel = styled.div`
  min-height: 80px;
  padding-top: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Name = styled.p`
  ${fonts.regular}
  text-align: left;
`;

export const Title = styled.p`
  ${fonts.regularSmall}
  margin-top: 1em;
`;

export const Text = styled.p`
  ${fonts.regularSmall}
  color: ${font.gray72};
`;

export const Status = styled.span`
  display: block;
  width: 13px;
  height: 13px;
  background: ${({ color }) => color};
  border-radius: 50%;
  margin-top: 15px;
`;

export const StatusLabel = styled.p`
  ${fonts.regularBold};
  font-size: 16px;
  color: ${({ color }) => color};
  text-align: left;
  margin-bottom: 15px;
`;

export const Button = styled.button`
  width: 32px;
  height: 32px;
`;

export const Menu = styled(posed.div({
  openConfirm: { height: 'auto' },
  closeConfirm: { height: 0 },
}))`
  overflow: hidden;
`;

export const Question = styled.p`
  ${fonts.regular}
  padding: 10px 0;
`;

export const No = styled(Button)`
  border: 1px solid ${colors.border.gray};
  border-radius: 11px;
  width: 100%;
  height: 38px;
  ${fonts.regular}
  color: ${colors.gray};
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 20px;
  }
`;

export const Yes = styled(No)`
  background: ${colors.green};
  color: ${colors.black};
  border: 1px solid ${colors.green};
`;
