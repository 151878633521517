import React from 'react';
import { Redirect } from 'react-router-dom';

import { usePermission, CANDIDATE, INSTITUTION } from 'permissions';

import Candidate from './Candidate';
import Institution from './Institution';

const Profile = () => {
  const can = usePermission();

  if (can.update(CANDIDATE)) return <Candidate />;
  if (can.update(INSTITUTION)) return <Institution />;

  return <Redirect to="/register" />;
};

export default Profile;
