/* eslint-disable no-console */
import { put, cancelled } from 'redux-saga/effects';
import { createAction } from 'redux-actions';
import {
  propOr, path, applySpec, pipe, map, prop, filter, head, not, isEmpty,
} from 'ramda';
import i18 from 'locale';

import {
  SUCCESS,
  ERROR,
  LOADING,
  META,
  TASK_ID,
  DEFAULT_ERROR,
} from './consts';
import {
  setLoading,
  setSuccess,
  setError,
  setCancel,
} from './actions';

export const withAlert = (saga) => function* wrapper(action) {
  const id = path([META, TASK_ID], action);

  yield put(setLoading(id));

  try {
    const data = yield saga(action);
    const success = prop('success', data);
    const error = prop('error', data);

    if (error && process.env.NODE_ENV === 'development') {
      console.error('sagaHandlerError: ', error);
    }

    if (error) {
      yield put(setError({
        id,
        error,
      }));
    } else {
      yield put(setSuccess({ id, success }));
    }
  } catch (error) {
    if (error && process.env.NODE_ENV === 'development') {
      console.error('sagaHandlerError: ', error);
    }

    yield put(setError({
      id,
      error: pipe(
        propOr(DEFAULT_ERROR, 'message'),
        (err) => i18.t(`errors:${err}`),
      )(error || {}),
    }));
  } finally {
    if (yield cancelled()) {
      yield put(setCancel(id));
    }
  }
};

const getCounter = (() => {
  let counter = 0;

  return () => {
    counter += 1;
    return counter;
  };
})();

export const creatAlertAction = (type) => createAction(
  type,
  ($) => $,
  () => ({ [TASK_ID]: getCounter() }),
);

export const mergeAlerts = (alerts) => applySpec({
  [ERROR]: pipe(
    map(prop(ERROR)),
    filter(Boolean),
    head,
  ),
  [SUCCESS]: pipe(
    map(prop(SUCCESS)),
    filter(not),
    isEmpty,
  ),
  [LOADING]: pipe(
    map(prop(LOADING)),
    filter(Boolean),
    head,
  ),
})(alerts);
