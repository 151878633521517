/* eslint-disable max-len */
import React, { useCallback } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Button from 'components/Button';
import {
  usePermission, SEARCH, CANDIDATE, INSTITUTION,
} from 'permissions';

import {
  Wrapper, HomePage, Container, Title, P, CandidateBackground, InstitutionBackground, FAQButton,
} from './styles';

const Home = () => {
  const can = usePermission();
  const { push } = useHistory();
  const { t } = useTranslation('home');

  const redirectToCandidate = useCallback(() => push('/register', { role: CANDIDATE }), [push]);
  const redirectToInstitution = useCallback(() => push('/register', { role: INSTITUTION }), [push]);
  const redirectToFAQ = useCallback(() => push('/about'), [push]);

  if (can.read(SEARCH)) return <Redirect to="/searches" />;
  if (can.read(CANDIDATE)) return <Redirect to="/profile" />;

  return (
    <Wrapper>
      <CandidateBackground />
      <InstitutionBackground />
      <HomePage>
        <Container>
          <Title>{t('Du willst helfen?')}</Title>
          <P>{t('Unsere Gesundheitsinstitutionen suchen Helfer*innen in Medizin, Pflege, Betreuung, Logistik, Küche und anderen Gebieten.')}</P>
          <Button type="buttom" onClick={redirectToCandidate}>{t('Jetzt Hilfe anbieten')}</Button>
        </Container>
        <Container>
          <Title>{t('Sie suchen Hilfe?')}</Title>
          <P>{t('Unsere Helfer*innen stehen bereit, um Unternehmen im Gesundheitswesen zu unterstützen.')}</P>
          <Button type="buttom" onClick={redirectToInstitution}>{t('Jetzt Hilfe suchen')}</Button>
        </Container>
        <FAQButton type="buttom" onClick={redirectToFAQ}>{t('Info')}</FAQButton>
      </HomePage>
    </Wrapper>
  );
};

export default Home;
