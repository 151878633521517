import styled from 'styled-components';
import posed from 'react-pose';

import { fonts, colors } from 'theme';

export const Menu = styled(posed.div({
  open: { height: 'auto' },
  close: { height: 0 },
}))`
  overflow: hidden;
  margin-bottom: 10px;
`;

export const Question = styled.p`
  ${fonts.regular}
  padding: 10px 0;
`;

export const No = styled.button`
  width: 32px;
  height: 32px;
  border: 1px solid ${colors.border.gray};
  border-radius: 11px;
  width: 100%;
  height: 38px;
  ${fonts.regular}
  color: ${colors.gray};
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 20px;
  }
`;

export const Yes = styled(No)`
  background: ${colors.red};
  color: ${colors.white};
  border: 1px solid ${colors.red};
`;
