import styled from 'styled-components';

import { colors, fonts } from 'theme';

const { background, white } = colors;

export const Label = styled.p`
  ${fonts.regular}
  margin-bottom: 20px;
`;

export const Message = styled.p`
  ${fonts.regular};
  max-width: 800px;
`;

export const Menu = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 500px;
  margin: 0 auto 40px auto;
`;

export const MenuBtn = styled.button`
  border-bottom: 4px solid ${({ active }) => (active ? background.blue : white)};
  ${fonts.title}
  font-size: 22px;
  transition: all .5s;
`;
