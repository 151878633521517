
export const URL = process.env.REACT_APP_URL || 'http://localhost:8121';

export const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8121/api';

export const APPLICATION_TOKEN = 'fe1f5ebe93e79568ce99b26119b60ecf42c2a570d0d85a421dd9667697bf4c66';

export const IN_PREPARATION = 'IN_PREPARATION';
export const ACTIVE = 'ACTIVE';
export const INACTIVE = 'INACTIVE';
export const CANDIDATE_STATUSES = {
  [IN_PREPARATION]: 0,
  [ACTIVE]: 1,
  [INACTIVE]: 2,
};
