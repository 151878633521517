import {
  takeLatest, takeEvery, call, put,
} from 'redux-saga/effects';

import api from 'api';
import { withAlert } from 'store/alerts';

import { updateMatches, updateMatch } from './actions';
import {
  CREATE_MATCH,
  FETCH_MATCHES,
  FETCH_MATCH,
  SAVE_MATCH,
} from './types';

function* createMatch({ payload }) {
  const data = yield call(api.postMatch, payload);
  yield put(updateMatch(data));
}

function* fetchMatches() {
  const data = yield call(api.getMatches);
  yield put(updateMatches(data));
}

function* fetchMatch({ payload }) {
  const data = yield call(api.getMatch, payload);
  yield put(updateMatch(data));
}

function* saveMatch({ payload }) {
  const data = yield call(api.putMatch, payload);
  yield put(updateMatch(data));
}

export default function* () {
  yield takeEvery(CREATE_MATCH, withAlert(createMatch));
  yield takeLatest(FETCH_MATCHES, withAlert(fetchMatches));
  yield takeEvery(FETCH_MATCH, withAlert(fetchMatch));
  yield takeEvery(SAVE_MATCH, withAlert(saveMatch));
}
