import React from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { LANG_LABELS } from 'locale/consts';

import Language from './Language';
import {
  Wrapper, Title, LinkWrapper, Block, Copyright, StyledLink, StyledExternalLink,
} from './styles';

const Footer = () => {
  const location = useLocation();
  const { t } = useTranslation('footer');


  if (location.pathname.includes('register')) return null;

  return (
    <Wrapper>
      <LinkWrapper>
        <Block>
          <Title>{t('Partner')}</Title>
          <StyledExternalLink href="https://care-base.ch/" target="_blank">care-base.ch</StyledExternalLink>
          <StyledExternalLink href="https://medignition.com/" target="_blank">medignition.com</StyledExternalLink>
        </Block>
        <Block>
          <Title>{t('Info')}</Title>
          <StyledExternalLink href="mailto:info@voluntaria.ch?subject=Support voluntaria.ch">{t('Support')}</StyledExternalLink>
          <StyledLink to="/imprint">{t('Impressum')}</StyledLink>
          <StyledLink to="/privacy">{t('Datenschutz')}</StyledLink>
        </Block>
        <Block>
          <Title>{t('Sprache')}</Title>
          {LANG_LABELS.map((lang) => <Language key={lang.id} {...lang} />)}
        </Block>
      </LinkWrapper>
      <Copyright>{`© ${new Date().getFullYear()} CareFair / medignition`}</Copyright>
    </Wrapper>
  );
};

export default Footer;
