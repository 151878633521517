import { object, string, array } from 'yup';

import i18n from 'locale';
import {
  NAME, SKILLS, EDUCATION, EXPERIENCE, AVAILABILITIES, REGIONS,
} from 'store/searches';

export const validationSchema = object().shape({
  [NAME]: string().required('Gib einen Namen für deine Suche ein.'),
  [SKILLS]: array().of(string().notRequired()),
  [EDUCATION]: string(),
  [EXPERIENCE]: string(),
  [AVAILABILITIES]: array().of(string().notRequired()),
  [REGIONS]: array().of(string().notRequired()),
});

export const getError = (error) => error && i18n.t(`errors:${error}`);
