import { handleActions } from 'redux-actions';
import { prop } from 'ramda';

import { normalizeArray } from 'store/utils';

import { ID } from './consts';
import { UPDATE_MATCHES, UPDATE_MATCH } from './types';

export default handleActions({
  [UPDATE_MATCHES]: (state, { payload }) => ({ ...state, ...normalizeArray(ID, payload) }),
  [UPDATE_MATCH]: (state, { payload }) => ({ ...state, [prop(ID, payload)]: payload }),
}, {});
