import styled from 'styled-components';

import { colors, fonts } from 'theme';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-right: 10px;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 10px;
`;

export const Circle = styled.div`
  position: absolute;
  width: 40px;
  height: 40px;
`;

export const First = styled(Circle)`
  clip: rect(0px, 20px, 40px, 0px);
  background: ${({ ratio }) => (ratio > 0.5 ? colors.indicator : colors.white)};
`;

export const Second = styled(Circle)`
  clip: rect(0px, 40px, 40px, 20px);
  background: ${colors.indicator};
  border-radius: 50%;
  transform: rotate(${({ ratio }) => -360 * ratio}deg);
`;

export const Third = styled(Circle)`
  clip: rect(0px, 40px, 40px, 20px);
  background: ${colors.white};
  opacity: ${({ ratio }) => (ratio < 0.5 ? 1 : 0)};
`;

export const Center = styled(Circle)`
  height: 32px;
  width: 32px;
  margin: 4px;
  border-radius: 50%;
  background: ${colors.white};
`;

export const P = styled.p`
  ${fonts.regularSmall}
  color: ${colors.indicator};
  max-width: 75px;
  text-align: right;
`;
