import { path } from 'ramda';

import {
  FEATURE_NAME as $,
  SUCCESS,
  ERROR,
  LOADING,
} from './consts';

export const getSuccess = (id) => path([$, id, SUCCESS]);
export const getError = (id) => path([$, id, ERROR]);
export const getLoading = (id) => path([$, id, LOADING]);
