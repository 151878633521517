import { useEffect, useMemo } from 'react';

import { wrapActions, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { useUser, CANDIDATE_ID } from 'store/session';
import { usePermission, CANDIDATE } from 'permissions';

import * as actions from './actions';
import { getCandidate, getCandidateProp } from './selectors';

export const useCandidatesActions = wrapActions({ ...actions });

export const useCandidate = (arg) => wrapMultiSelector(getCandidateProp, getCandidate)(arg);

export const useInitCandidate = () => {
  const can = usePermission();
  const candidateId = useUser(CANDIDATE_ID);
  const { fetchCandidate } = useCandidatesActions();
  const alerts = useAlerts();
  const { extractId, id } = alerts;
  const hasAccess = useMemo(() => can.read(CANDIDATE) && can.update(CANDIDATE), [can]);

  useEffect(() => {
    if (hasAccess && !id) {
      extractId(fetchCandidate(candidateId));
    }
  }, [hasAccess, id, extractId, fetchCandidate, candidateId]);

  return hasAccess ? alerts : { success: true };
};
