import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { isEmpty } from 'ramda';
import { useTranslation } from 'react-i18next';

import BackPanel from 'components/BackPanel';
import Input from 'components/FormInput';
import DateInput from 'components/DateInput';
import Button from 'components/Button';
import Message from 'components/Message';
import Spinner from 'components/Spinner';
import {
  ID, EMPLOYER, POSITION, START, END,
} from 'store/experiences';

import { useInitialValues, useSubmitForm } from './hooks';
import { validationSchema, getError } from './validation';
import {
  Container, Wrapper, MessageWrap, Text,
} from './styles';

const Experience = () => {
  const [onSubmit, alerts] = useSubmitForm();
  const { t } = useTranslation(['experience', 'form']);

  const initialValues = useInitialValues();
  const handleSubmitClick = useCallback(() => alerts.resetAlerts(), [alerts]);
  const {
    values, handleSubmit, getFieldProps, setFieldValue, errors,
  } = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
    onSubmit,
  });

  const handleChangeStartDate = useCallback((date) => setFieldValue(START, date), [setFieldValue]);
  const handleErrorStartDate = useCallback((err) => setFieldValue('isStartDateError', err || ''), [setFieldValue]);
  const handleChangeEndDate = useCallback((date) => setFieldValue(END, date), [setFieldValue]);
  const handleErrorEndDate = useCallback((err) => setFieldValue('isEndDateError', err || ''), [setFieldValue]);

  return (
    <Container onSubmit={handleSubmit}>
      <BackPanel title={t(values[ID] ? 'Eintrag bearbeiten' : 'Neuer Eintrag')} to="/experiences" />
      <Text>{t('Bei welchem Arbeitgeber und in welcher Funktion hast Du gearbeitet?')}</Text>
      <Input label={t('Arbeitgeber')} {...getFieldProps(EMPLOYER)} error={getError(errors[EMPLOYER])} />
      <Input label={t('Funktion')} {...getFieldProps(POSITION)} error={getError(errors[POSITION])} />
      <Text>{t('form:Von')}</Text>
      <DateInput
        value={values[START]}
        onChange={handleChangeStartDate}
        onError={handleErrorStartDate}
      />
      <Text>{t('form:bis')}</Text>
      <DateInput
        value={values[END]}
        onChange={handleChangeEndDate}
        onError={handleErrorEndDate}
      />
      <Wrapper>
        <Button
          type="submit"
          color="blue"
          disabled={alerts.loading}
          onClick={handleSubmitClick}
        >
          {alerts.loading ? <Spinner size={40} /> : t('form:Speichern')}
        </Button>
        <MessageWrap>
          {(alerts.error || !isEmpty(errors)) && (
            <Message error>{alerts.error || t('form:Wir haben einen Fehler in deinen Angaben gefunden. Bitte überprüfe deine Angaben.')}</Message>
          )}
          {alerts.success && <Message success>{t('form:Vielen Dank für deine Angaben. Gesundheitsinstitutionen können ab sofort auf dein Profil zugreifen und dich für einen Einsatz anfragen. Die Anfragen erhältst du per E-Mail.')}</Message>}
        </MessageWrap>
      </Wrapper>
    </Container>
  );
};

export default Experience;
