export default {
  maxWidth: 1024,
  minWidth: 350,
  mobileWidth: 375,
  shadow: {
    button: '0 2px 17px 0 rgba(186, 186, 186, 0.5)',
    input: 'inset 0 0 4px 0 rgba(101, 100, 100, 0.5)',
    inputError: 'inset 0 0 4px 0 rgba(255, 0, 0, 1)',
  },
  header: {
    height: 70,
  },
};
