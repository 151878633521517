import {
  object, number, string, array,
} from 'yup';

import i18n from 'locale';

import {
  ID,
  REGIONS,
  AVAILABILITIES,
  AVAILABLE_FROM,
  HOURS_PER_WEEK,
  AVAILABILITY_COMMENT,
} from 'store/candidates';

export const validationSchema = object().shape({
  [ID]: string().required(i18n.t('errors:Oops, ein Fehler ist aufgetreten. Hilf uns, dein Benutzererlebnis zu verbessern und sende uns einen Fehlerbericht.')),
  [REGIONS]: array().of(string().notRequired()),
  [AVAILABILITIES]: array().of(string().notRequired()),
  [AVAILABLE_FROM]: string().notRequired(),
  [HOURS_PER_WEEK]: number().min(0, i18n.t('errors:Ungültiger Wert')).max(40, i18n.t('errors:Max. 40h / Woche möglich')).notRequired(),
  [AVAILABILITY_COMMENT]: string().notRequired(),
  isDateError: string().max(0, i18n.t('errors:Ungültiges Datum')),
});
