import i18n from 'locale';
import { pipe, prop, __ } from 'ramda';

import { ERRORS } from './consts';

const hightMonths = [1, 3, 5, 7, 8, 10, 12];

export const currentYear = new Date().getFullYear();

export const getMaxDayOfMonth = (month, year) => {
  const m = Number(month) || 0;
  const y = Number(year) || 0;

  if (m === 2 && y % 4 === 0) return 29;
  if (m === 2 && y % 4) return 28;

  if (hightMonths.includes(m)) return 31;

  return 30;
};

export const getError = pipe(
  prop(__, ERRORS),
  (name) => i18n.t(`errors:${name}`),
);
