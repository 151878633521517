import React from 'react';
import PropTypes from 'prop-types';

import Spinner from 'components/Spinner';

import {
  Container, Button, Thumb, Text,
} from './styles';

const Switcher = ({
  label, checked, onClick, disabled,
}) => (
  <Container>
    <Text>{label}</Text>
    <Button type="button" onClick={onClick} checked={checked} disabled={disabled}>
      <Thumb checked={checked}>
        {disabled && <Spinner size={26} />}
      </Thumb>
    </Button>
  </Container>
);

Switcher.defaultProps = {
  label: '',
  checked: false,
  onClick: () => null,
  disabled: false,
};

Switcher.propTypes = {
  label: PropTypes.string,
  checked: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Switcher;
