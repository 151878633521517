import styled from 'styled-components';
import { metrics } from 'theme';

export const Container = styled.form`
  max-width: ${metrics.mobileWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: flex-between;
  height: 100%;
  min-height: 450px;
  margin: 0 auto;
  padding-bottom: 80px;
`;
