import React from 'react';
import { useTranslation } from 'react-i18next';

import Section from 'components/Section';
import Item from 'components/FormTextItem';
import ProfilePhoto from 'components/ProfilePhoto';
import RedirectBtn from 'components/RedirectBtn';
import { getLanguageLabel } from 'utils';
import {
  FIRST_NAME, LAST_NAME, EMAIL, LANGUAGE, useUser,
} from 'store/session';
import {
  NAME, LOGO, ADDRESS, PHONE, ZIP_CODE, CITY, useInstitution, useInstitutionsActions,
} from 'store/institutions';

import { Container } from './styles';

const Institution = () => {
  const [
    institutionName,
    institutionLogo,
    institutionAddress,
    institutionPhone,
    institutionZipCode,
    institutionCity,
  ] = useInstitution([
    NAME,
    LOGO,
    ADDRESS,
    PHONE,
    ZIP_CODE,
    CITY,
  ]);
  const [
    userFirstName, userLastName, userEmail, userLanguage,
  ] = useUser([
    FIRST_NAME, LAST_NAME, EMAIL, LANGUAGE,
  ]);

  const { t } = useTranslation('profile');
  const { uploadInstitutionPhoto } = useInstitutionsActions();

  return (
    <Container>
      <ProfilePhoto url={institutionLogo} action={uploadInstitutionPhoto} interactive />
      <Section title={institutionName} withoutBorder />
      <Section title={t('Institution')}>
        <Item label={t('Name')} text={institutionName} />
        <Item label={t('Adresse')} text={institutionAddress} />
        <Item label={t('PLZ')} text={institutionZipCode} />
        <Item label={t('Ort')} text={institutionCity} />
        <Item label={t('Telefon')} text={institutionPhone} />
        <RedirectBtn to="/institution" />
      </Section>
      <Section title={t('Persönliche Angaben')}>
        <Item label={t('Name')} text={`${userFirstName} ${userLastName}`} />
        <Item label={t('E-Mail')} text={userEmail} />
        <Item label={t('Sprache')} text={getLanguageLabel(userLanguage)} />
        <RedirectBtn to="/personal" />
      </Section>
    </Container>
  );
};

export default Institution;
