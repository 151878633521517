import styled from 'styled-components';

import { metrics, colors, fonts } from 'theme';

export default styled.button`
  width: 100%;
  height: 60px;
  border-radius: 11px;
  border: 1px solid ${({ color }) => color || colors.white};
  background: ${({ color, confirm }) => (color && !confirm ? color : colors.white)};
  box-shadow: ${metrics.shadow.button};
  ${fonts.regular}
  color: ${({ color }) => (color === 'blue' ? colors.font.blue : colors.black)};
  transition: all .5s;
  margin-bottom: 15px;
`;
