import posed from 'react-pose';
import styled from 'styled-components';

import { colors, fonts, metrics } from 'theme';

export const Button = styled(posed.button({
  open: {
    scale: 1,
    opacity: 1,
  },
  close: {
    scale: 0,
    opacity: 0,
    transition: { duration: 0 },
  },
}))`
  width: calc(100% - 20px);
  height: calc(100% / ${({ length }) => length});
  border-bottom: 1px solid ${colors.black};
  background: ${colors.white};
  margin: 0 10px;
  ${fonts.menu}
  &:last-child {
    border-bottom: none;
  }
  @media (max-width: ${metrics.maxWidth + 20}px) {
    width: 100%;
    margin: 0;
  }
`;
