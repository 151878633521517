import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import Icon from 'assets/svg/BackArrow';

import { Wrapper, Button, Title } from './styles';

const BackPanel = ({ title, to }) => {
  const { push } = useHistory();
  const handleClick = useCallback(() => push(to), [push, to]);

  return (
    <Wrapper>
      <Button type="button" onClick={handleClick}>
        <Icon />
      </Button>
      <Title>{title}</Title>
    </Wrapper>
  );
};

BackPanel.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

export default BackPanel;
