import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { isEmpty } from 'ramda';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import BackPanel from 'components/BackPanel';
import MultiLayerSelection from 'components/MultiLayerSelection';
import DateInput from 'components/DateInput';
import Button from 'components/Button';
import Message from 'components/Message';
import Spinner from 'components/Spinner';
import {
  REGIONS,
  AVAILABILITIES,
  AVAILABLE_FROM,
  HOURS_PER_WEEK,
  AVAILABILITY_COMMENT,
} from 'store/candidates';
import { useContent, AVAILABILITIES_CONTENT, REGIONS_CONTENT } from 'content';

import { useInitialValues, useSubmitForm } from './hooks';
import { validationSchema } from './validation';
import {
  Container,
  Wrapper,
  MessageWrap,
  MultiLayerWrap,
  Input,
  Textarea,
  Text,
} from './styles';

const Availability = () => {
  const { push } = useHistory();
  const [onSubmit, alerts] = useSubmitForm();
  const { t } = useTranslation(['availability', 'form']);
  const availabilities = useContent(AVAILABILITIES_CONTENT);
  const regions = useContent(REGIONS_CONTENT);

  const initialValues = useInitialValues();
  const handleSubmitClick = useCallback(() => alerts.resetAlerts(), [alerts]);
  const {
    values,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    errors,
  } = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
    onSubmit,
  });

  const redirectToProfile = useCallback(() => push('/profile'), [push]);

  const updateRegions = useCallback((val) => setFieldValue(REGIONS, val), [
    setFieldValue,
  ]);
  const updateAvailabilities = useCallback(
    (val) => setFieldValue(AVAILABILITIES, val),
    [setFieldValue],
  );

  const handleChangeDate = useCallback(
    (date) => setFieldValue(AVAILABLE_FROM, date),
    [setFieldValue],
  );
  const handleErrorDate = useCallback(
    (err) => setFieldValue('isDateError', err || ''),
    [setFieldValue],
  );

  return (
    <Container onSubmit={handleSubmit}>
      <BackPanel title={t('Verfügbarkeit')} to="/profile" />
      <Text>{t('Mir ist ein Einsatz in folgenden Regionen möglich')}</Text>
      <MultiLayerWrap>
        <MultiLayerSelection
          data={regions}
          selected={values[REGIONS]}
          onUpdate={updateRegions}
        />
      </MultiLayerWrap>
      <DateInput
        key={values[AVAILABLE_FROM]}
        label={t('Frühestmögliche Verfügbarkeit')}
        value={values[AVAILABLE_FROM]}
        onChange={handleChangeDate}
        onError={handleErrorDate}
      />
      <Input
        type="number"
        label={t('Max. Anzahl Stunden / Woche')}
        {...getFieldProps(HOURS_PER_WEEK)}
        error={errors[HOURS_PER_WEEK]}
      />
      <Text>{t('Verfügbare Wochentage und Zeiten')}</Text>
      <MultiLayerSelection
        data={availabilities}
        selected={values[AVAILABILITIES]}
        onUpdate={updateAvailabilities}
      />
      <Textarea
        placeholder={t('Anmerkungen')}
        {...getFieldProps(AVAILABILITY_COMMENT)}
        rows={8}
      />
      <Wrapper>
        <Button type="button" onClick={redirectToProfile}>
          {t('form:Abbrechen')}
        </Button>
        <Button
          type="submit"
          color="blue"
          disabled={alerts.loading}
          onClick={handleSubmitClick}
        >
          {alerts.loading ? <Spinner size={40} /> : t('form:Speichern')}
        </Button>
        <MessageWrap>
          {(alerts.error || !isEmpty(errors)) && (
            <Message error>
              {alerts.error
                || t('form:Wir haben einen Fehler in deinen Angaben gefunden. Bitte überprüfe deine Angaben.')}
            </Message>
          )}
          {alerts.success && (
            <Message success>
              {t('form:Vielen Dank für deine Angaben. Gesundheitsinstitutionen können ab sofort auf dein Profil zugreifen und dich für einen Einsatz anfragen. Die Anfragen erhältst du per E-Mail.')}
            </Message>
          )}
        </MessageWrap>
      </Wrapper>
    </Container>
  );
};

export default Availability;
