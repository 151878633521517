import React from 'react';
import PropTypes from 'prop-types';

import Plus from 'assets/svg/Plus';

import { Button, Text } from './styles';

const CreateButton = ({ title, onClick }) => (
  <Button type="button" onClick={onClick}>
    <Text>{title}</Text>
    <Plus />
  </Button>
);

CreateButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CreateButton;
