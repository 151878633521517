import {
  map, applySpec, prop, props, propOr, pipe,
} from 'ramda';

import {
  ID, START, END, POSITION, EMPLOYER,
} from 'store/experiences';
import { formatDate } from 'utils';

const parseDate = ([a, b], t) => {
  if (a && !b) return formatDate(a);
  if (!a && b) return `${t('bis')} ${formatDate(b)}`;
  if (a && b) return `${formatDate(a)} – ${formatDate(b)}`;

  return t('Keine Zeitdauer angegeben');
};

export const handleList = (t) => map(applySpec({
  id: prop(ID),
  date: pipe(props([START, END]), (data) => parseDate(data, t)),
  position: propOr(t('Keine Funktion angegeben'), POSITION),
  employer: propOr(t('Kein Arbeitgeber angegeben'), EMPLOYER),
}));
