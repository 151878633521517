import posed from 'react-pose';

const duration = 500;

export const AnimateImage = posed.div({
  open: {
    rotate: 45,
    transition: duration,
  },
  close: {
    rotate: 0,
    transition: duration,
  },
});
