import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { Btn } from './styles';

const RedirectBtn = ({ to }) => {
  const { push } = useHistory();
  const { t } = useTranslation('form');
  const handleClick = useCallback(() => push(to), [push, to]);

  return <Btn type="button" onClick={handleClick}>{t('Bearbeiten')}</Btn>;
};

RedirectBtn.propTypes = {
  to: PropTypes.string.isRequired,
};

export default RedirectBtn;
