import styled from 'styled-components';
import { colors } from 'theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
  width: 100%;
`;

export const Wrapper = styled.div`
  width: ${({ amount }) => amount * 20}px;
  display: flex;
  justify-content: space-between;
`;

export const Dot = styled.span`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: ${({ isActive }) => (isActive ? colors.blue : colors.gray)};
  cursor: pointer;
`;
