import styled from 'styled-components';

import { fonts, metrics } from 'theme';
import FormInput from 'components/FormInput';

export const Container = styled.form`
  width: 100%;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  margin: 60px 0 50px 0;

  & > * {
    margin-bottom: 20px;
  }
`;

export const MessageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px; 
`;

export const MultiLayerWrap = styled.div`
  margin: 30px 0;
`;

export const Input = styled(FormInput)`
  margin: 50px 0;
`;

export const Textarea = styled.textarea`
  width: 100%;
  border-radius: 19px;
  ${fonts.regular}
  padding: 10px 15px;
  margin: 40px 0;
  box-shadow: ${metrics.shadow.input};
  resize: none;
`;

export const Text = styled.p`
  ${fonts.regular}
  margin: 20px 0;
`;
