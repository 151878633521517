export const ABOUT = 'about';
export const PRIVACY = 'privacy';
export const SKILLS_CONTENT = 'skills';
export const REGIONS_CONTENT = 'regions';
export const AVAILABILITIES_CONTENT = 'availabilities';
export const EDUCATIONS_CONTENT = 'educations';
export const EXPERIENCES_CONTENT = 'experiences';
export const MENU = 'menu';
export const IMPRINT = 'imprint';

export const CONTENT_LIST = [
  ABOUT,
  PRIVACY,
  SKILLS_CONTENT,
  REGIONS_CONTENT,
  AVAILABILITIES_CONTENT,
  EDUCATIONS_CONTENT,
  EXPERIENCES_CONTENT,
  MENU,
  IMPRINT,
];
