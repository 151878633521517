import styled from 'styled-components';

import { colors, fonts } from 'theme';

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Button = styled.button`
  height: 80px;
  background: ${colors.white};
  padding-bottom: 10px;
`;

export const Title = styled.p`
  flex: 1 1 auto;
  text-align: center;
  padding: 0 10px;
  ${fonts.title}
`;
