import styled from 'styled-components';

import { AnimateImage } from './utils';

export const Button = styled(AnimateImage)`
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 1;
  margin: 0;
  padding: 0;
`;
