import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_SEARCHES = getName('UPDATE_SEARCHES');
export const RESET_SEARCHES = getName('RESET_SEARCHES');
export const UPDATE_SEARCH = getName('UPDATE_SEARCH');
export const DELETE_SEARCH = getName('DELETE_SEARCH');
export const UPDATE_RECOMMENDATIONS = getName('UPDATE_RECOMMENDATIONS');
export const RESET_RECOMMENDATIONS = getName('RESET_RECOMMENDATIONS');
export const UPDATE_RECOMMENDATION = getName('UPDATE_RECOMMENDATION');
export const DELETE_RECOMMENDATION = getName('DELETE_RECOMMENDATION');

export const FETCH_SEARCHES = getName('FETCH_SEARCHES');
export const FETCH_SEARCH = getName('FETCH_SEARCH');
export const CREATE_SEARCH = getName('CREATE_SEARCH');
export const SAVE_SEARCH = getName('SAVE_SEARCH');
export const REMOVE_SEARCH = getName('REMOVE_SEARCH');
export const FETCH_RECOMMENDATIONS = getName('FETCH_RECOMMENDATIONS');
