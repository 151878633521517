import { useEffect } from 'react';

import { wrapActions, wrapMultiSelector, wrapSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { useUser, INSTITUTION } from 'store/session';
import { usePermission, INSTITUTION as INSTITUTION_PERMIT, MATCH } from 'permissions';

import * as actions from './actions';
import { getInstitution, getInstitutionProp, getFavorites } from './selectors';

export const useInstitutionsActions = wrapActions({ ...actions });

export const useInstitution = (arg) => wrapMultiSelector(getInstitutionProp, getInstitution)(arg);
export const useFavorites = wrapSelector(getFavorites);

export const useInitInstitution = () => {
  const can = usePermission();
  const hasAccess = can.read(INSTITUTION_PERMIT);
  const institutionId = useUser(INSTITUTION);
  const alerts = useAlerts();
  const { extractId, id } = alerts;
  const { fetchInstitution } = useInstitutionsActions();

  useEffect(() => {
    if (hasAccess && institutionId && !id) {
      extractId(fetchInstitution(institutionId));
    }
  }, [hasAccess, institutionId, id, extractId, fetchInstitution]);

  return hasAccess && institutionId ? alerts : { success: true };
};

export const useInitFavorites = () => {
  const can = usePermission();
  const hasAccess = can.read(INSTITUTION_PERMIT) && can.read(MATCH) && can.update(MATCH);
  const institutionId = useUser(INSTITUTION);
  const alerts = useAlerts();
  const { extractId, id } = alerts;
  const { fetchFavorites, resetFavorites } = useInstitutionsActions();

  useEffect(() => {
    if (hasAccess && institutionId && !id) {
      extractId(fetchFavorites(institutionId));
    }
  }, [hasAccess, institutionId, id, extractId, fetchFavorites]);

  useEffect(() => resetFavorites, [resetFavorites]);

  return hasAccess && institutionId ? alerts : { success: true };
};
