import { createAction } from 'redux-actions';

import { creatAlertAction } from 'store/alerts';

import {
  UPDATE_SEARCHES,
  RESET_SEARCHES,
  UPDATE_SEARCH,
  DELETE_SEARCH,
  FETCH_SEARCHES,
  FETCH_SEARCH,
  CREATE_SEARCH,
  SAVE_SEARCH,
  REMOVE_SEARCH,
  UPDATE_RECOMMENDATIONS,
  RESET_RECOMMENDATIONS,
  UPDATE_RECOMMENDATION,
  DELETE_RECOMMENDATION,
  FETCH_RECOMMENDATIONS,
} from './types';

export const updateSearches = createAction(UPDATE_SEARCHES);
export const resetSearches = createAction(RESET_SEARCHES);
export const updateSearch = createAction(UPDATE_SEARCH);
export const deleteSearch = createAction(DELETE_SEARCH);
export const updateRecommendations = createAction(UPDATE_RECOMMENDATIONS);
export const resetRecommendations = createAction(RESET_RECOMMENDATIONS);
export const updateRecommendation = createAction(UPDATE_RECOMMENDATION);
export const deleteRecommendation = createAction(DELETE_RECOMMENDATION);

export const fetchSearches = creatAlertAction(FETCH_SEARCHES);
export const fetchSearch = creatAlertAction(FETCH_SEARCH);
export const createSearch = creatAlertAction(CREATE_SEARCH);
export const saveSearch = creatAlertAction(SAVE_SEARCH);
export const removeSearch = creatAlertAction(REMOVE_SEARCH);
export const fetchRecommendations = creatAlertAction(FETCH_RECOMMENDATIONS);
