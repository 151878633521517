import React, { useState, useEffect, useCallback } from 'react';
import {
  pipe, propOr, reduce, prop,
} from 'ramda';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Message from 'components/Message';

import {
  DAY, MONTH, YEAR, MAIN,
} from './consts';
import { getError } from './utils';
import validation from './validation';
import {
  Container, Label, Wrapper, Input,
} from './styles';

const DateInput = ({
  label, value, onChange, onError,
}) => {
  const [day, setDay] = useState('');
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [errors, setErrors] = useState();
  const [isFocus, setIsFocus] = useState(false);

  const { t } = useTranslation('form');

  const checkDate = useCallback(() => {
    setErrors();

    if (!(day || month || year) || isFocus) return;

    validation.validate({
      [DAY]: day || 0,
      [MONTH]: month || 0,
      [YEAR]: year || 0,
    }, { abortEarly: false }).then(() => {
      const newDate = new Date(year, month - 1, day).toISOString();

      if (value !== newDate) {
        onChange(newDate);
      }
    }).catch(pipe(
      propOr([], 'inner'),
      reduce((acc, { path, message }) => { acc[path] = message; return acc; }, {}),
      setErrors,
    ));
  }, [day, month, year, isFocus, onChange, value]);

  const handleDay = useCallback((e) => setDay(e.target.value), []);
  const handleMonth = useCallback((e) => setMonth(e.target.value), []);
  const handleYear = useCallback((e) => setYear(e.target.value), []);

  const handleFocus = useCallback(() => setIsFocus(true), []);
  const handleBlur = useCallback(() => setIsFocus(false), []);

  useEffect(() => {
    const outsideDate = new Date(value);
    const isCorrect = value && outsideDate.toDateString() !== 'Invalid Date';

    setDay(isCorrect ? outsideDate.getDate() : '');
    setMonth(isCorrect ? outsideDate.getMonth() + 1 : '');
    setYear(isCorrect ? outsideDate.getFullYear() : '');
  }, [value]);

  useEffect(() => { checkDate(); }, [checkDate]);

  useEffect(() => {
    if (errors) {
      onError(getError(MAIN), errors);
    } else {
      onError();
    }
  }, [errors, onError]);

  return (
    <Container>
      <Label>{label}</Label>
      <Wrapper>
        <Input
          placeholder={t('TT')}
          value={day}
          onChange={handleDay}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={prop(DAY, errors || {})}
        />
        <Input
          placeholder={t('MM')}
          value={month}
          onChange={handleMonth}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={prop(MONTH, errors || {})}
        />
        <Input
          placeholder={t('JJJJ')}
          value={year}
          onChange={handleYear}
          onFocus={handleFocus}
          onBlur={handleBlur}
          error={prop(YEAR, errors || {})}
        />
      </Wrapper>
      {errors && <Message padding="7px 0 12px 0" error={errors}>{getError(MAIN)}</Message>}
    </Container>
  );
};

DateInput.defaultProps = {
  label: '',
  value: '',
  onChange: () => null,
  onError: () => null,
};

DateInput.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onError: PropTypes.func,
};

export default DateInput;
