import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60">
    <g fill="none" fillRule="evenodd" stroke="#A2A2A2" strokeLinecap="round" strokeWidth="2">
      <path d="M15 18L15 43.362 40.587 43.362" transform="rotate(45 27.793 30.68)" />
      <path d="M52.159 30.5L10 30.5" />
    </g>
  </svg>
);
