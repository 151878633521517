import {
  takeEvery, takeLatest, call, put,
} from 'redux-saga/effects';
import { prop } from 'ramda';

import api from 'api';
import { withAlert } from 'store/alerts';

import { ID } from './consts';
import {
  updateSearches,
  updateSearch,
  deleteSearch,
  updateRecommendations,
} from './actions';
import {
  FETCH_SEARCHES,
  FETCH_SEARCH,
  CREATE_SEARCH,
  SAVE_SEARCH,
  REMOVE_SEARCH,
  FETCH_RECOMMENDATIONS,
} from './types';

function* fetchSearches() {
  const data = yield call(api.getSearches);
  yield put(updateSearches(data));
}

function* fetchSearch({ payload }) {
  const data = yield call(api.getSearch, payload);
  yield put(updateSearch(data));
}

function* createSearch({ payload }) {
  const data = yield call(api.postSearch, payload);
  yield put(updateSearch(data));
}

function* saveSearch({ payload }) {
  if (prop(ID, payload)) {
    const data = yield call(api.putSearch, payload);
    yield put(updateSearch(data));

    return { success: data };
  }

  const newData = yield call(api.postSearch, payload);
  yield put(updateSearch(newData));

  return { success: newData };
}

function* removeSearch({ payload }) {
  const data = yield call(api.deleteSearch, payload);
  yield put(deleteSearch(data));
}

function* fetchRecommendations({ payload }) {
  const data = yield call(api.postRecommendations, payload);
  yield put(updateRecommendations(data));
}

export default function* () {
  yield takeLatest(FETCH_SEARCHES, withAlert(fetchSearches));
  yield takeLatest(FETCH_SEARCH, withAlert(fetchSearch));
  yield takeEvery(CREATE_SEARCH, withAlert(createSearch));
  yield takeLatest(SAVE_SEARCH, withAlert(saveSearch));
  yield takeEvery(REMOVE_SEARCH, withAlert(removeSearch));
  yield takeLatest(FETCH_RECOMMENDATIONS, withAlert(fetchRecommendations));
}
