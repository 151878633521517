import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';
import { omit } from 'ramda';

import api from 'api';
import { withAlert } from 'store/alerts';
import { ID } from 'store/session/consts';
import { getUser } from 'store/session/selectors';

import { updateCandidate } from './actions';
import {
  CREATE_CANDIDATE,
  FETCH_CANDIDATE,
  SAVE_CANDIDATE,
} from './types';

function* createCandidate() {
  const user = yield select(getUser);

  const data = yield call(api.postCandidate, omit([ID], user));
  yield put(updateCandidate(data));
}

function* fetchCandidate({ payload }) {
  if (!payload) {
    yield createCandidate();
    return;
  }

  const data = yield call(api.getCandidate, payload);

  if (data) {
    yield put(updateCandidate(data));
  } else {
    yield createCandidate();
  }
}

function* saveCandidate({ payload }) {
  const data = yield call(api.putCandidate, payload);
  yield put(updateCandidate(data));
}

export default function* () {
  yield takeLatest(CREATE_CANDIDATE, withAlert(createCandidate));
  yield takeLatest(FETCH_CANDIDATE, withAlert(fetchCandidate));
  yield takeLatest(SAVE_CANDIDATE, withAlert(saveCandidate));
}
