import { combineReducers } from 'redux';

import { reducerTemplate } from 'store/utils';
import { ID as USER_ID } from 'store/session';

import { ID, LIST, RECOMMENDATIONS } from './consts';
import {
  UPDATE_SEARCHES,
  RESET_SEARCHES,
  UPDATE_SEARCH,
  DELETE_SEARCH,
  UPDATE_RECOMMENDATIONS,
  RESET_RECOMMENDATIONS,
  UPDATE_RECOMMENDATION,
  DELETE_RECOMMENDATION,
} from './types';

export default combineReducers({
  [LIST]: reducerTemplate({
    id: ID,
    list: UPDATE_SEARCHES,
    item: UPDATE_SEARCH,
    deleteItem: DELETE_SEARCH,
    reset: RESET_SEARCHES,
  }, {}),
  [RECOMMENDATIONS]: reducerTemplate({
    id: USER_ID,
    list: UPDATE_RECOMMENDATIONS,
    item: UPDATE_RECOMMENDATION,
    deleteItem: DELETE_RECOMMENDATION,
    reset: RESET_RECOMMENDATIONS,
  }, {}),
});
