import { all, spawn } from 'redux-saga/effects';

import watchSession from 'store/session/sagas';
import watchCandidates from 'store/candidates/sagas';
import watchExperiences from 'store/experiences/sagas';
import watchSearches from 'store/searches/sagas';
import watchInstitutions from 'store/institutions/sagas';
import watchMatches from 'store/matches/sagas';

export default function* () {
  yield all([
    spawn(watchSession),
    spawn(watchCandidates),
    spawn(watchExperiences),
    spawn(watchSearches),
    spawn(watchInstitutions),
    spawn(watchMatches),
  ]);
}
