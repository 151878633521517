import styled from 'styled-components';

import Btn from 'components/Button';
import { fonts } from 'theme';

export const Button = styled(Btn)`
  display: flex;
  justify-content: space-between;
  padding: 0 25px 0 35px;
  margin: 30px 0 50px 0;
  align-items: center;
`;

export const Text = styled.p`${fonts.title}`;
