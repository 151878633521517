import { createAction } from 'redux-actions';

import { creatAlertAction } from 'store/alerts';

import {
  UPDATE_INSTITUTION,
  FETCH_INSTITUTION,
  SAVE_INSTITUTION,
  UPLOAD_INSTITUTION_PHOTO,
  UPDATE_FAVORITES,
  RESET_FAVORITES,
  FETCH_FAVORITES,
} from './types';

export const updateInstitution = createAction(UPDATE_INSTITUTION);
export const updateFavorites = createAction(UPDATE_FAVORITES);
export const resetFavorites = createAction(RESET_FAVORITES);

export const fetchInstitution = creatAlertAction(FETCH_INSTITUTION);
export const saveInstitution = creatAlertAction(SAVE_INSTITUTION);
export const uploadInstitutionPhoto = creatAlertAction(UPLOAD_INSTITUTION_PHOTO);
export const fetchFavorites = creatAlertAction(FETCH_FAVORITES);
