import styled from 'styled-components';

import { metrics } from 'theme';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: ${metrics.header.height + 10}px 15px 0 15px;
  @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {  
    height: auto;
  }
`;

export const Wrapper = styled.div`
  flex: 1 1 0;
  width: 100%;
  max-width: ${metrics.maxWidth}px;
`;
