import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { path } from 'ramda';

import { useAlerts } from 'store/alerts/hooks';
import Spinner from 'components/Spinner';
import Message from 'components/Message';
import Icon from 'assets/svg/Plus';

import { Container, Input } from './styles';

const ProfilePhoto = ({ url, action, interactive }) => {
  const { extractId, loading, error } = useAlerts();
  const handleSelect = useCallback((e) => {
    const file = path(['target', 'files', 0], e);
    if (file) {
      extractId(action(file));
    }
  }, [extractId, action]);

  return interactive ? (
    <>
      <Container url={url}>
        {!(url || loading) && <Icon />}
        {loading && <Spinner />}
        <Input
          type="file"
          accept="image/*"
          onChange={handleSelect}
        />
      </Container>
      {error && <Message error>{error}</Message>}
    </>
  ) : <Container url={url} />;
};

ProfilePhoto.defaultProps = {
  url: '',
  action: () => null,
  interactive: false,
};

ProfilePhoto.propTypes = {
  url: PropTypes.string,
  action: PropTypes.func,
  interactive: PropTypes.bool,
};

export default ProfilePhoto;
