/* eslint-disable react/no-array-index-key */
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useContent, ABOUT } from 'content';
import Accordion from './Accordion';
import {
  Introduction, Title, P, StyledLink,
} from './styles';

const About = () => {
  const { t } = useTranslation('about');
  const faq = useContent(ABOUT);

  return (
    <>
      <Introduction>
        <P>{t('Voluntaria verbindet Helfer*innen mit Gesundheitsinstitutionen')}</P>
      </Introduction>
      {faq.map((item) => (
        <Accordion key={item.id} title={<Title>{item.title}</Title>}>
          {item.values.map((text, index) => (
            <P key={index}>{text.value}</P>
          ))}
        </Accordion>
      ))}
      <Accordion title={<Title>{t('Wer steckt hinter Voluntaria?')}</Title>}>
        <P>
          {t('Voluntaria ist eine Initiative von')}
          {' '}
          <StyledLink href="https://care-base.ch/" target="_blank">CareBase</StyledLink>
          {' '}
          {t('und')}
          {' '}
          <StyledLink href="https://medignition.com/" target="_blank">medignition</StyledLink>
          {'. '}
          {t('Voluntaria ist unser Beitrag, um Menschen während der Corona-Pandemie zu helfen.')}
        </P>
      </Accordion>
      <Accordion title={<Title>{t('Arbeitet Voluntaria auch mit anderen Plattformen?')}</Title>}>
        <P>
          {t('Der Austausch mit anderen Plattformen ist für uns sehr wichtig. Deshalb stehen wir in engem Kontakt mit')}
          {' '}
          <StyledLink href="https://carenow.ch/" target="_blank">Care Now</StyledLink>
          {' '}
          {t('und')}
          {' '}
          <StyledLink href="https://students4hospitals.ch/" target="_blank">Students4Hospitals</StyledLink>
          {'. '}
          {t('Findet eine Gesundheitsinstitution auf unserer Plattform also nicht die geeigneten Helfer*innen, leiten wir die Anfrage gerne an unsere Partner weiter.')}
        </P>
      </Accordion>
      <Accordion title={<Title>{t('Wie kann ich Voluntaria kontaktieren?')}</Title>}>
        <P>
          {t('Schreib uns einfach eine E-Mail an')}
          {' '}
          <StyledLink href="mailto:info@voluntaria.ch?subject=voluntaria.ch">info@voluntaria.ch</StyledLink>
          {'. '}
          {t('Wir sind gerne für dich da.')}
        </P>
      </Accordion>
    </>
  );
};

export default About;
