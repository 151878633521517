import React, { useState, useCallback } from 'react';

import Menu from '../Menu';
import {
  Container, Wrapper, Label, ButtonWrapper,
} from './styles';
import MenuButton from './MenuButton';

const Header = () => {
  const [open, setOpen] = useState(false);

  const handleClick = useCallback(() => setOpen(!open), [open]);

  return (
    <>
      <Container>
        <Wrapper>
          <Label to="/">Voluntaria</Label>
          <ButtonWrapper>
            <MenuButton open={open} onClick={handleClick} />
          </ButtonWrapper>
        </Wrapper>
      </Container>
      <Menu open={open} id="mobileMenu" closeMenu={handleClick} />
    </>
  );
};

export default Header;
