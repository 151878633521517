import styled from 'styled-components';

import { colors, fonts } from 'theme';

const { background, white } = colors;

export const Container = styled.div` 
  margin-bottom: 40px;
`;

export const Wrapper = styled.div`
  width: 100%;
  border-radius: 11px;
  background: ${background.blue};
  padding: 16px;
  margin: 30px 0;
`;

export const Title = styled.p`
  ${fonts.regularBold}
  color: ${white};
`;

export const Text = styled.p`
  ${fonts.regularSmall}
  color: ${white};
`;
