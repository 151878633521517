import { combineReducers } from 'redux';

import { FEATURE_NAME as alerts } from 'store/alerts/consts';
import alertsReducer from 'store/alerts/reducer';

import { FEATURE_NAME as session } from 'store/session/consts';
import sessionReducer from 'store/session/reducer';

import { FEATURE_NAME as candidates } from 'store/candidates/consts';
import candidatesReducer from 'store/candidates/reducer';

import { FEATURE_NAME as experiences } from 'store/experiences/consts';
import experiencesReducer from 'store/experiences/reducer';

import { FEATURE_NAME as searches } from 'store/searches/consts';
import searchesReducer from 'store/searches/reducer';

import { FEATURE_NAME as institutions } from 'store/institutions/consts';
import institutionsReducer from 'store/institutions/reducer';

import { FEATURE_NAME as matches } from 'store/matches/consts';
import matchesReducer from 'store/matches/reducer';

export default combineReducers({
  [alerts]: alertsReducer,
  [session]: sessionReducer,
  [candidates]: candidatesReducer,
  [experiences]: experiencesReducer,
  [searches]: searchesReducer,
  [institutions]: institutionsReducer,
  [matches]: matchesReducer,
});
