export const FEATURE_NAME = 'session';

export const ID = '_id';
export const AUTH = 'auth';
export const USER = 'user';
export const IS_EXIST = 'isExist';
export const PERMISSIONS = 'permissions';

export const INSTITUTION_NAME = 'institutionName';
export const CANDIDATE_ID = 'candidate';

export const FIRST_NAME = 'firstname';
export const LAST_NAME = 'lastname';
export const EMAIL = 'email';
export const ROLES = 'roles';
export const PASSWORD = 'password';
export const PROFILE_PHOTO = 'profilePicture';
export const GENDER = 'gender';
export const ADDRESS = 'address';
export const ZIP_CODE = 'zipCode';
export const CITY = 'city';
export const MOBILE = 'mobile';
export const BIRTH = 'birthday';
export const INSTITUTION = 'institution';
export const STATUS = 'status';
export const LANGUAGE = 'language';

// unknown

export const AHV_NUMBER = 'ahvNumber';
export const NATIONALITY = 'nationality';
export const SETTLE_PERMIT = 'settlePermit';
