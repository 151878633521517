import {
  pipe, map, cond, length, min, T, sum, when, find, propEq, prop,
} from 'ramda';

export const estimateProfile = pipe(
  map(
    cond([
      [Boolean, pipe(length, min(1))],
      [T, () => 0],
    ]),
  ),
  ($) => sum($) / $.length,
);

export const getLabel = (id, list) => when(
  Boolean,
  pipe(
    (val) => find(propEq('id', val), list),
    prop('name'),
  ),
)(id);
