import { prop, omit } from 'ramda';

import { ID } from 'store/experiences';

import { wrapperRequest } from './utils';
import {
  API_URL, POST, PUT, DELETE,
} from './consts';

export const postExperience = (body = {}) => wrapperRequest({
  url: `${API_URL}/experiences`,
  options: { method: POST, body: omit([ID], body) },
});

export const getExperiences = () => wrapperRequest({
  url: `${API_URL}/experiences`,
});

export const getExperience = (experienceId) => wrapperRequest({
  url: `${API_URL}/experiences/${experienceId}`,
});

export const putExperience = (body = {}) => wrapperRequest({
  url: `${API_URL}/experiences/${prop(ID, body)}`,
  options: { method: PUT, body: omit([ID], body) },
});

export const deleteExperience = (experienceId) => wrapperRequest({
  url: `${API_URL}/experiences/${experienceId}`,
  options: { method: DELETE },
});
