import { object, number } from 'yup';

import { currentYear, getMaxDayOfMonth, getError } from './utils';
import {
  DAY, MONTH, YEAR,
} from './consts';

export default object().shape({
  [DAY]: number().when([MONTH, YEAR], (month, year, schema) => schema
    .min(1, getError(DAY))
    .max(getMaxDayOfMonth(month, year), getError(DAY))),
  [MONTH]: number().min(1, getError(MONTH)).max(12, getError(MONTH)),
  [YEAR]: number().min(1900, getError(YEAR)).max(currentYear + 100, getError(YEAR)),
});
