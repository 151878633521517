import { useEffect } from 'react';

import { wrapActions, wrapSelector, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';

import {
  getAuth,
  getUser,
  getUserProp,
  getUserId,
  getIsLoggedIn,
} from './selectors';
import * as actions from './actions';

export const useSessionActions = wrapActions({ ...actions });

export const useAuth = wrapSelector(getAuth);
export const useUserId = wrapSelector(getUserId);
export const useUser = wrapMultiSelector(getUserProp, getUser);
export const useIsLoggedIn = wrapSelector(getIsLoggedIn);

export const useInitSession = () => {
  const alerts = useAlerts();
  const isAuth = useAuth();
  const { restore } = useSessionActions();
  const { extractId, id } = alerts;

  useEffect(() => {
    if (isAuth && !id) {
      extractId(restore());
    }
  }, [isAuth, id, extractId, restore]);

  return isAuth ? alerts : { success: true };
};
