import styled from 'styled-components';

import { fonts } from 'theme';

export const Container = styled.div`
  margin-bottom: 40px;
`;

export const Msg = styled.p`
  ${fonts.regular};
  max-width: 800px;
`;
