import React from 'react';
import { useTranslation } from 'react-i18next';

import BackPanel from 'components/BackPanel';
import Message from 'components/Message';
import Spinner from 'components/Spinner';
import CandidateList from 'components/CandidateList';
import { useFavorites, useInitFavorites } from 'store/institutions';
import { useMatches } from 'store/matches';

import { Container } from './styles';

const Favorites = () => {
  const { loading, error } = useInitFavorites();
  const favorites = useFavorites() || [];
  const matches = useMatches() || [];
  const { t } = useTranslation('profile');

  if (error) return <Message error>{error}</Message>;
  if (loading) return <Spinner height="calc(100vh - 265px)" />;

  return (
    <Container>
      <BackPanel title={t('Favoriten')} to="/searches" />
      <CandidateList items={favorites} matches={matches} type="favorites" />
    </Container>
  );
};

export default Favorites;
