import React, {
  useCallback, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Message from 'components/Message';
import { getError } from '../validation';

import MultiInput from './MultiInput';
import {
  Container, Wrapper, Label, Input,
} from './styles';

const Carousel = ({
  getFieldProps, errors, getFieldHelpers,
}) => {
  const institutionRef = useRef();
  const nameRef = useRef();
  const emailRef = useRef();
  const multiRef = useRef();
  const { value: step } = getFieldProps('step');
  const emailError = getError(errors.email);
  const passwordError = getError(errors.password);
  const { t } = useTranslation('register');

  const handleEmailInput = useCallback((e) => {
    const { setValue, setError } = getFieldHelpers('email');

    if (emailError) {
      setError();
    }

    setValue(e.target.value);
  }, [emailError, getFieldHelpers]);

  const handleMultiInput = useCallback((value) => {
    const { setValue, setError } = getFieldHelpers('password');

    if (passwordError) {
      setError();
    }

    setValue(value);
  }, [passwordError, getFieldHelpers]);

  const handleCompleteInstitutionPose = useCallback((pose) => {
    if (pose === 'show') {
      institutionRef.current.focus();
    }
  }, []);

  const handleCompleteNamePose = useCallback((pose) => {
    if (pose === 'show') {
      nameRef.current.focus();
    }
  }, []);

  const handleCompletePasswordPose = useCallback((pose) => {
    if (pose !== 'show') return;

    const firstInput = multiRef.current.querySelector('input');

    if (firstInput) {
      firstInput.focus();
    }
  }, []);

  useEffect(() => {
    emailRef.current.focus();
  }, []);

  return (
    <Container>
      <Wrapper pose={step === 1 ? 'show' : 'hideLeft'}>
        <Label>{t('E-Mail')}</Label>
        <Input
          ref={emailRef}
          placeholder={t('E-Mail')}
          {...getFieldProps('email')}
          onChange={handleEmailInput}
          error={emailError}
          disabled={step !== 1}
        />
        <Message padding="5px 20px" error={emailError}>{emailError}</Message>
      </Wrapper>
      <Wrapper pose={step === 2 ? 'show' : 'hideLeft'} onPoseComplete={handleCompleteInstitutionPose}>
        <Label>Institution</Label>
        <Input
          ref={institutionRef}
          placeholder={t('Name')}
          {...getFieldProps('institution')}
          disabled={step !== 2}
        />
      </Wrapper>
      <Wrapper pose={step === 3 ? 'show' : 'hideLeft'} onPoseComplete={handleCompleteNamePose}>
        <Label>{t('Name')}</Label>
        <Input
          ref={nameRef}
          placeholder={t('Vorname Nachname')}
          {...getFieldProps('name')}
          disabled={step !== 3}
        />
      </Wrapper>
      <Wrapper
        ref={multiRef}
        pose={step === 4 ? 'show' : 'hideRight'}
        onPoseComplete={handleCompletePasswordPose}
      >
        <Label>{t('E-Mail bestätigen')}</Label>
        <MultiInput
          {...getFieldProps('password')}
          onChange={handleMultiInput}
          error={passwordError}
          disabled={step !== 4}
        />
        {passwordError ? (
          <Message padding="0 5px" error>{passwordError}</Message>
        ) : (
          <Message padding="0 5px" simple>
            {t('Wir haben dir einen Bestätigungscode an deine E-Mail-Adresse gesendet.')}
          </Message>
        )}
      </Wrapper>
    </Container>
  );
};

Carousel.propTypes = {
  getFieldProps: PropTypes.func.isRequired,
  errors: PropTypes.objectOf(PropTypes.string).isRequired,
  getFieldHelpers: PropTypes.func.isRequired,
};

export default Carousel;
