import styled from 'styled-components';

import { fonts } from 'theme';
import Button from 'components/Button';

export const Btn = styled(Button)`
  border-radius: 11px;
  height: 38px;
  ${fonts.regular}
`;
