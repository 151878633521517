import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { values } from 'ramda';

import { wrapActions, wrapMultiSelector } from 'store/utils';
import { useAlerts } from 'store/alerts/hooks';
import { usePermission, MATCH } from 'permissions';

import * as actions from './actions';
import { getMatches, getMatch, getMatchProp } from './selectors';

export const useMatchesActions = wrapActions({ ...actions });

export const useMatches = () => {
  const matches = useSelector(getMatches);

  return useMemo(() => values(matches), [matches]);
};

export const useMatch = (id, arg) => wrapMultiSelector(getMatchProp(id), getMatch(id))(arg);

export const useInitMatches = () => {
  const can = usePermission();
  const { fetchMatches } = useMatchesActions();
  const alerts = useAlerts();
  const { extractId, id } = alerts;
  const hasAccess = can.read(MATCH) && can.update(MATCH);

  useEffect(() => {
    if (hasAccess && !id) {
      extractId(fetchMatches());
    }
  }, [hasAccess, id, extractId, fetchMatches]);

  return hasAccess ? alerts : { success: true };
};
