import React from 'react';
import PropTypes from 'prop-types';

import { Container, LeftLine, RightLine } from './styles';

const Icon = ({ className, ...rest }) => (
  <Container className={className}>
    <LeftLine {...rest} />
    <RightLine {...rest} />
  </Container>
);

Icon.defaultProps = {
  className: '',
};

Icon.propTypes = {
  className: PropTypes.string,
};

export default Icon;
