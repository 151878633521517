import { object, string, array } from 'yup';

import {
  SKILLS, SKILLS_COMMENT, EXPERIENCE, EDUCATION,
} from 'store/candidates';

export const validationSchema = object().shape({
  [SKILLS]: array().of(string().notRequired()),
  [SKILLS_COMMENT]: string().notRequired(),
  [EXPERIENCE]: string().notRequired(),
  [EDUCATION]: string().notRequired(),
});
