import React from 'react';
import PropTypes from 'prop-types';
import Button from './styles';

const CheckBoxButton = ({
  label, level, isActive, hasActiveChildren, onClick,
}) => (
  <Button
    type="button"
    level={level}
    isActive={isActive}
    hasActiveChildren={hasActiveChildren}
    onClick={onClick}
  >
    {label}
  </Button>
);

CheckBoxButton.defaultProps = {
  level: 1,
};

CheckBoxButton.propTypes = {
  label: PropTypes.string.isRequired,
  level: PropTypes.number,
  isActive: PropTypes.bool.isRequired,
  hasActiveChildren: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default CheckBoxButton;
