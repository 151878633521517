import {
  takeLatest, call, put, select,
} from 'redux-saga/effects';

import api from 'api';
import { withAlert } from 'store/alerts';

import { ID, LOGO } from './consts';
import { updateInstitution, updateFavorites } from './actions';
import { getInstitutionProp } from './selectors';
import {
  FETCH_INSTITUTION, SAVE_INSTITUTION, UPLOAD_INSTITUTION_PHOTO, FETCH_FAVORITES,
} from './types';

function* fetchInstitution({ payload }) {
  const data = yield call(api.getInstitution, payload);
  yield put(updateInstitution(data));
}

function* saveInstitution({ payload }) {
  const data = yield call(api.putInstitution, payload);
  yield put(updateInstitution(data));
}

function* getFavorites({ payload }) {
  const data = yield call(api.getFavorites, payload);
  yield put(updateFavorites(data));
}

function* uploadInstitutionPhoto({ payload }) {
  const { url } = yield call(api.uploadFile, payload);
  const institutionId = yield select(getInstitutionProp(ID));
  const data = yield call(api.putInstitution, { [ID]: institutionId, [LOGO]: url });
  yield put(updateInstitution(data));
}

export default function* () {
  yield takeLatest(FETCH_INSTITUTION, withAlert(fetchInstitution));
  yield takeLatest(SAVE_INSTITUTION, withAlert(saveInstitution));
  yield takeLatest(UPLOAD_INSTITUTION_PHOTO, withAlert(uploadInstitutionPhoto));
  yield takeLatest(FETCH_FAVORITES, withAlert(getFavorites));
}
