import styled from 'styled-components';

import { metrics, fonts, colors } from 'theme';

export const Container = styled.div`
  width: 100%;
  height: ${({ error }) => (error ? 135 : 120)}px;
`;

export const Label = styled.p`
  ${fonts.regular}
  padding-bottom: 4px;
`;

export const OptionList = styled.select`
  outline: 0;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 11px;
  box-shadow: ${({ error }) => (error ? metrics.shadow.inputError : metrics.shadow.input)};
  & > select {
    width: 100%;
    height: 56px;
    outline: none;
    border: none;
    background: transparent;
    padding: 0 40px 0 15px;
    cursor: pointer;
    ${fonts.regular}
    -webkit-appearance: none;
    appearance: none;
  }
  & > select::-ms-expand {
    display: none;
  }

  & > select:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #000;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  height: 56px;
  width: 40px;
  padding: 8px 15px;
  z-index: -1;
`;

export const Triangle = styled.div`
  height: 0;
  width: 0;
  border: 7px solid;
  border-color: ${({ top }) => (top
    ? `transparent transparent ${colors.blue} transparent`
    : `${colors.blue} transparent transparent transparent`)};
`;
