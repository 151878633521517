import { object, string } from 'yup';
import i18n from 'locale';

export const validationSchema = object().shape({
  email: string()
    .when('step', {
      is: (value) => value === 1,
      then: string().required('E-Mail-Adresse fehlt').email('E-Mail-Adresse ist ungültig'),
      otherwise: string().notRequired(),
    }),
  password: string().when('step', {
    is: (value) => value === 4,
    then: string().length(5, 'Dein Bestätigungscode ist ungültig'),
    otherwise: string().notRequired(),
  }),
});

export const getError = (error) => error && i18n.t(`errors:${error}`);
