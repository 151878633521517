import { useMemo, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { prop } from 'ramda';

import {
  ID, NAME, SKILLS, EDUCATION, EXPERIENCE, AVAILABILITIES, REGIONS,
  useSearch, useAlerts, useSearchesActions,
} from 'store/searches';

const fields = [
  ID, NAME, SKILLS, EDUCATION, EXPERIENCE, AVAILABILITIES, REGIONS,
];

const arrayField = [SKILLS, AVAILABILITIES, REGIONS];

export const useInitialValues = (searchId) => {
  const search = useSearch(searchId) || {};

  return useMemo(() => fields.reduce((acc, field) => {
    acc[field] = (arrayField.includes(field)) ? search[field] || [] : search[field] || '';

    return acc;
  }, {}), [search]);
};

export const useSubmitForm = (id) => {
  const { push } = useHistory();
  const searchId = useSearch(id, ID);
  const { saveSearch } = useSearchesActions();
  const alerts = useAlerts();
  const { extractId, success } = alerts;

  const onSubmit = useCallback((values) => {
    extractId(saveSearch(values));
  }, [extractId, saveSearch]);

  useEffect(() => {
    if (success) {
      push(`/recommendations/${prop(ID, success)}`);
    }
  }, [success, searchId, id, push]);

  return [onSubmit, alerts];
};
