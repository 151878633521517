/* eslint-disable react/no-array-index-key */
import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Section from 'components/Section';
import ProfilePhoto from 'components/ProfilePhoto';
import ArrowIcon from 'components/ArrowIcon';
import StatusButton from 'components/StatusButton';
import Message from 'components/Message';
import Spinner from 'components/Spinner';
import { colors } from 'theme';

import { useSubmitActions } from './hooks';
import {
  Container, HeadWrap, TextWrap, Name, Panel, Title,
  Text, Status, StatusLabel, Experience, Menu, Question, Yes, No,
} from './styles';

const Item = ({
  id,
  match,
  firstName,
  lastName,
  photo,
  skills,
  skillsComment,
  availabilityComment,
  experience,
  education,
  experiences,
  regions,
  availableFrom,
  hoursPerWeek,
  availabilities,
}) => {
  const [open, setOpen] = useState(false);
  const handleClick = useCallback(() => setOpen(!open), [open]);
  const { t } = useTranslation(['search', 'availability']);

  const {
    confirmMenu,
    changeConfirmMenu,
    statusLabel,
    statusColor,
    isActive,
    isInactive,
    markerName,
    requestMarket,
    markerLoading,
    connectName,
    requestConnect,
    connectLoading,
    loading,
    error,
  } = useSubmitActions(id, match);

  return (
    <Container pose={open ? 'open' : 'close'}>
      <HeadWrap type="button" onClick={handleClick}>
        <TextWrap>
          <Name>{firstName}</Name>
          <Name>{lastName}</Name>
          {(open && match) && (
            <StatusLabel color={statusColor}>{statusLabel}</StatusLabel>
          )}
        </TextWrap>
        <Panel>
          <ArrowIcon pose={open ? 'open' : 'close'} />
          {match && <Status color={statusColor} />}
        </Panel>
      </HeadWrap>
      <Section>
        <ProfilePhoto url={photo} />
      </Section>
      <Section title={t('Fähigkeiten')}>
        <Title>{t('Hintergrund')}</Title>
        <Text>{skills || t('Keine Angaben')}</Text>
        <Text>{skillsComment}</Text>
        <Title>{t('Höchster Ausbildungsabschluss')}</Title>
        <Text>{education || t('Keine Angaben')}</Text>
        <Title>{t('Anzahl Jahre Berufserfahrung')}</Title>
        <Text>{experience || t('Keine Angaben')}</Text>
        <Title>{t('Berufserfahrung')}</Title>
        {experiences.length === 0 ? <Text>{t('Keine Angaben')}</Text> : (
          <div>
            {experiences.map((item, i) => (
              <Experience key={i}>
                {item.map((subItem, y) => (<Text key={y}>{subItem}</Text>))}
              </Experience>
            ))}
          </div>
        )}
      </Section>
      <Section title={t('availability:Verfügbarkeit')}>
        <Title>{t('availability:Region')}</Title>
        <Text>{regions || t('Keine Angaben')}</Text>
        <Title>{t('availability:Mögliches Startdatum')}</Title>
        <Text>{availableFrom || t('Keine Angaben')}</Text>
        <Title>{t('availability:Max. Anzahl Stunden / Woche')}</Title>
        <Text>{hoursPerWeek || t('Keine Angaben')}</Text>
        <Title>{t('availability:Verfügbare Wochentage')}</Title>
        <Text>{availabilities || t('Keine Angaben')}</Text>
        <Text>{availabilityComment}</Text>
      </Section>
      {isInactive || (
      <StatusButton
        type="button"
        onClick={requestMarket}
        color={statusColor}
        disabled={loading || isActive}
      >
        {markerLoading ? <Spinner size={40} /> : markerName}
      </StatusButton>
      )}
      <StatusButton
        type="button"
        onClick={changeConfirmMenu}
        confirm={confirmMenu}
        color={(isInactive || confirmMenu) ? colors.green : 'none'}
        disabled={loading || isInactive}
      >
        {connectLoading ? <Spinner size={40} /> : connectName}
      </StatusButton>
      <Menu pose={confirmMenu ? 'openConfirm' : 'closeConfirm'}>
        <Question>{t('Möchtest Du jetzt eine Kontaktanfrage per E-Mail senden?')}</Question>
        <Yes type="button" onClick={requestConnect}>{t('Ja, senden')}</Yes>
        <No type="button" onClick={changeConfirmMenu}>{t('Nein')}</No>
      </Menu>
      {error && <Message error>{error}</Message>}
    </Container>
  );
};

Item.defaultProps = {
  match: {},
  firstName: '',
  lastName: '',
  photo: '',
  skills: '',
  skillsComment: '',
  education: '',
  experience: '',
  experiences: [],
  regions: '',
  availableFrom: '',
  hoursPerWeek: null,
  availabilities: '',
  availabilityComment: '',
};

Item.propTypes = {
  id: PropTypes.string.isRequired,
  match: PropTypes.shape({
    user: PropTypes.string,
    status: PropTypes.number,
  }),
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  photo: PropTypes.string,
  skills: PropTypes.string,
  skillsComment: PropTypes.string,
  experience: PropTypes.string,
  education: PropTypes.string,
  experiences: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  regions: PropTypes.string,
  availableFrom: PropTypes.string,
  hoursPerWeek: PropTypes.number,
  availabilities: PropTypes.string,
  availabilityComment: PropTypes.string,
};

export default Item;
