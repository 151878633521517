export const FEATURE_NAME = 'candidates';

export const ID = '_id';

export const SKILLS = 'skills';
export const SKILLS_COMMENT = 'skillsComment';
export const EDUCATION = 'education';
export const AVAILABILITIES = 'availabilites';
export const AVAILABILITY_COMMENT = 'availabilityComment';
export const AVAILABLE_FROM = 'availableFrom';
export const HOURS_PER_WEEK = 'hoursPerWeek';
export const EXPERIENCE = 'experience';
export const REGIONS = 'regions';
