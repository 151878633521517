import posed from 'react-pose';
import styled from 'styled-components';

import { metrics, colors } from 'theme';

export const Container = styled(posed.div({
  open: {
    height: 'auto',
    bottom: 0,
  },
  close: {
    height: 0,
    bottom: 'auto',
    transition: { duration: 0 },
  },
}))`
  position: fixed;
  top: ${metrics.header.height}px;
  left: calc(50% - ${metrics.maxWidth / 2 + 10}px);
  width: 100%;
  max-width: ${metrics.maxWidth + 20}px;
  background: ${colors.white};
  z-index: 10;
  overflow: hidden;
  @media (max-width: ${metrics.maxWidth + 20}px) {
    left: 0;
    padding: 0 15px;
  }
`;
