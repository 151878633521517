import { useMemo, useCallback } from 'react';
import { omit } from 'ramda';

import {
  ID,
  REGIONS,
  AVAILABILITIES,
  AVAILABLE_FROM,
  HOURS_PER_WEEK,
  AVAILABILITY_COMMENT,
  useCandidate, useCandidatesActions, useAlerts,
} from 'store/candidates';

const fields = [
  ID,
  REGIONS,
  AVAILABILITIES,
  AVAILABLE_FROM,
  HOURS_PER_WEEK,
  AVAILABILITY_COMMENT,
];

const arrayField = [REGIONS, AVAILABILITIES];

export const useInitialValues = () => {
  const candidate = useCandidate();

  return useMemo(() => fields.reduce((acc, field) => {
    acc[field] = (arrayField.includes(field)) ? candidate[field] || [] : candidate[field] || '';

    return acc;
  }, { isDateError: '' }), [candidate]);
};

export const useSubmitForm = () => {
  const { saveCandidate } = useCandidatesActions();
  const alerts = useAlerts();
  const { extractId } = alerts;

  const onSubmit = useCallback((values) => {
    extractId(saveCandidate(omit(['isDateError'], values)));
  }, [extractId, saveCandidate]);

  return [onSubmit, alerts];
};
