import styled from 'styled-components';
import posed from 'react-pose';

import { colors, fonts } from 'theme';

export const Container = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.gray};
`;

export const Title = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: ${({ align }) => align};
  width: 100%;
  min-height: 70px;
  cursor: pointer;
`;

export const TitleWrapper = styled.div`
  ${fonts.title}
  padding-bottom: 4px;
`;

export const Content = styled(posed.div({
  open: { height: 'auto' },
  close: { height: 0 },
}))`
  overflow: hidden;
`;
