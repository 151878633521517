import { createAction } from 'redux-actions';

import { creatAlertAction } from 'store/alerts';

import {
  UPDATE_USER,
  LOGOUT,
  SIGNUP,
  LOGIN,
  RESTORE,
  CREATE_USER,
  SAVE_USER,
  UPLOAD_USER_PHOTO,
  SET_PERMISSIONS,
} from './types';

export const updateUser = createAction(UPDATE_USER);
export const logout = createAction(LOGOUT);
export const setPermissions = createAction(SET_PERMISSIONS);

export const signup = creatAlertAction(SIGNUP);
export const login = creatAlertAction(LOGIN);
export const restore = creatAlertAction(RESTORE);
export const createUser = creatAlertAction(CREATE_USER);
export const saveUser = creatAlertAction(SAVE_USER);
export const uploadUserPhoto = creatAlertAction(UPLOAD_USER_PHOTO);
