import { useMemo } from 'react';

import {
  ID,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  ADDRESS,
  ZIP_CODE,
  CITY,
  MOBILE,
  BIRTH,
  AHV_NUMBER,
  NATIONALITY,
  SETTLE_PERMIT,
  LANGUAGE,
  useUser,
} from 'store/session';

const fields = [
  ID,
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  ADDRESS,
  ZIP_CODE,
  CITY,
  MOBILE,
  BIRTH,
  AHV_NUMBER,
  NATIONALITY,
  SETTLE_PERMIT,
  LANGUAGE,
];

export const useInitialValues = () => {
  const user = useUser() || {};

  return useMemo(() => fields.reduce((acc, field) => {
    acc[field] = user[field] || '';

    return acc;
  }, { isDateError: '' }), [user]);
};
