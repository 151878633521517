import styled from 'styled-components';

import { metrics, colors, fonts } from 'theme';

export default styled.button`
  ${fonts.regular}
  width: ${({ level }) => (level === 3 ? 'auto' : '100%')};
  height: ${({ level }) => (level === 1 ? '60px' : '38px')};
  border-radius: 11px;
  border: ${({ level, isActive, hasActiveChildren }) => {
    if ((isActive && hasActiveChildren) || (level === 1 && isActive && !hasActiveChildren)) return `1px solid ${colors.blue}`;
    if (isActive || level === 1) return `1px solid ${colors.white}`;

    return `1px solid ${colors.gray}`;
  }};
  background: ${({ isActive, hasActiveChildren }) => {
    if (isActive && !hasActiveChildren) return colors.blue;
    return colors.white;
  }};
  color: ${({ isActive, hasActiveChildren }) => {
    if (isActive && !hasActiveChildren) return colors.white;
    if (isActive) return colors.black;

    return colors.gray;
  }};
  box-shadow: ${({ level }) => {
    if (level === 1) return metrics.shadow.button;
    return 'none';
  }};
  transition: all 0.5s;
  margin: ${({ level }) => (level === 3 ? '0 5px 5px 0' : '0 0 11px 0')};
  padding: 0 15px;
`;
