import { select } from 'redux-saga/effects';
import { useSelector } from 'react-redux';
import {
  reduce, merge, pathOr, path,
} from 'ramda';

import { getIsLoggedIn, getPermissions } from 'store/session/selectors';

import {
  CREATE, READ, UPDATE, DELETE,
} from './consts';
import access from './access';

export const extractPermissions = (roles = []) => (
  reduce((acc, role) => merge(access[role], acc), {}, roles)
);

function* allowSaga(source, permit) {
  const isLoggedIn = yield select(getIsLoggedIn);
  const permissions = yield select(getPermissions);

  if (!(isLoggedIn && path([source, permit], permissions))) {
    throw new Error('Du hast keine Berechtigung, auf diese Daten zuzugreifen.');
  }
}

export const sagaCan = {
  create: (source) => allowSaga(source, CREATE),
  read: (source) => allowSaga(source, READ),
  update: (source) => allowSaga(source, UPDATE),
  delete: (source) => allowSaga(source, DELETE),
};

const checkAccess = (permissions) => ({
  create: (source) => pathOr(false, [source, CREATE], permissions),
  read: (source) => pathOr(false, [source, READ], permissions),
  update: (source) => pathOr(false, [source, UPDATE], permissions),
  delete: (source) => pathOr(false, [source, DELETE], permissions),
});

export const usePermission = () => {
  const isLoggedIn = useSelector(getIsLoggedIn);
  const permission = useSelector(getPermissions);

  return checkAccess(isLoggedIn ? permission : {});
};

export * from './consts';
