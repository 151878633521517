import styled from 'styled-components';
import { UpImg, DownImg } from './utils';

export const Container = styled.div`
  position: absolute;
  top: 23px;
  right: 0;
  width: 15vw;
  height: 35px;
  z-index: 2;
  cursor: pointer;
`;

export const UpImgLine = styled(UpImg)`
  position: absolute;
  right: 0;
  width: 40px;
  height: 2px;
  background: #000;
`;

export const DownImgLine = styled(DownImg)`
  position: absolute;
  right: 0;
  width: 40px;
  height: 2px;
  background: #000;
`;
