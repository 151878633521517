import {
  map, applySpec, prop, pathOr, pipe, path, props, when, filter, pathEq, cond, equals, T, any,
} from 'ramda';

import { getSkills, getRegions, getAvailabilities } from 'utils/contentHelpers';
import { CANDIDATE, EXPERIENCES } from 'store/searches';
import {
  ID, FIRST_NAME, LAST_NAME, PROFILE_PHOTO,
} from 'store/session';
import {
  SKILLS, SKILLS_COMMENT, EDUCATION, REGIONS, EXPERIENCE,
  AVAILABLE_FROM, HOURS_PER_WEEK, AVAILABILITIES, AVAILABILITY_COMMENT,
} from 'store/candidates';
import {
  START, END, POSITION, EMPLOYER,
} from 'store/experiences';
import { STATUS } from 'store/matches';
import { formatDate } from 'utils';
import { CANDIDATE_STATUSES } from 'utils/consts';

const { ACTIVE, INACTIVE } = CANDIDATE_STATUSES;

const parseDate = ([a, b]) => {
  if (a && !b) return formatDate(a);
  if (!a && b) return `bis ${formatDate(b)}`;
  if (a && b) return `${formatDate(a)} - ${formatDate(b)}`;

  return '';
};

const parseExperiences = map((item = {}) => [
  parseDate(props([START, END], item)),
  prop(POSITION, item),
  prop(EMPLOYER, item),
]);

export const handleList = (matches, filterStatus) => pipe(
  map(applySpec({
    id: prop(ID),
    match: pipe(prop(ID), (id) => matches.find(({ user }) => user === id)),
    firstName: prop(FIRST_NAME),
    lastName: prop(LAST_NAME),
    photo: prop(PROFILE_PHOTO),
    skills: pipe(pathOr([], [CANDIDATE, SKILLS]), getSkills),
    skillsComment: path([CANDIDATE, SKILLS_COMMENT]),
    experience: path([CANDIDATE, EXPERIENCE]),
    education: path([CANDIDATE, EDUCATION]),
    experiences: pipe(pathOr([], [EXPERIENCES]), parseExperiences),
    regions: pipe(pathOr([], [CANDIDATE, REGIONS]), getRegions),
    availableFrom: pipe(path([CANDIDATE, AVAILABLE_FROM]), formatDate),
    hoursPerWeek: path([CANDIDATE, HOURS_PER_WEEK]),
    availabilities: pipe(pathOr([], [CANDIDATE, AVAILABILITIES]), getAvailabilities),
    availabilityComment: path([CANDIDATE, AVAILABILITY_COMMENT]),
  })),
  when(
    () => any(equals(filterStatus), [ACTIVE, INACTIVE]),
    filter(pathEq(['match', STATUS], filterStatus)),
  ),
);

const messageList = {
  favorites: {
    [ACTIVE]: 'Markiere Helfer*innen aus deinen Suchergebnissen, um sie zu deinen Favoriten hinzuzufügen.',
    [INACTIVE]: 'Kontaktiere Helfer*innen aus deinen Suchergebnissen, um sie zu deinen Favoriten hinzuzufügen.',
    default: 'Kontaktiere Helfer*innen aus deinen Suchergebnissen, um sie zu deinen Favoriten hinzuzufügen.',
  },
  recommendations: {
    [ACTIVE]: 'Markiere Helfer*innen aus deinen Suchergebnissen, um sie zu deinen Favoriten hinzuzufügen und später zu kontaktieren.',
    [INACTIVE]: 'Kontaktiere Helfer*innen aus deinen Suchergebnissen.',
    default: 'Wir konnten keine Helfer*innen für deine Suche finden. Bitte ändere deine Suche und versuche weniger Suchkriterien festzulegen.',
  },
};

export const getEmptyListMessage = (type) => cond([
  [equals(ACTIVE), (kind) => path([type, kind], messageList)],
  [equals(INACTIVE), (kind) => path([type, kind], messageList)],
  [T, () => path([type, 'default'], messageList)],
]);
