export default {
  white: '#fff',
  black: '#000',
  gray: '#9b9b9b',
  blue: '#4964ff',
  red: '#ff3939',
  yellow: '#ffcd00',
  green: '#00c664',
  font: {
    lightblue: '#8092ff',
    blue: '#4964ff',
    gray: '#a2a2a2',
    gray72: '#727272',
    error: '#f00',
    success: '#47a77a',
  },
  border: {
    gray: '#9b9b9b',
  },
  switcher: {
    active: '#4cd964',
    inactive: '#a2a2a2',
  },
  background: {
    blue: '#3754ff',
  },
  indicator: '#00c664',
};
