import { createAction } from 'redux-actions';

import { creatAlertAction } from 'store/alerts';

import {
  UPDATE_CANDIDATE,
  CREATE_CANDIDATE,
  FETCH_CANDIDATE,
  SAVE_CANDIDATE,
} from './types';

export const updateCandidate = createAction(UPDATE_CANDIDATE);

export const createCandidate = creatAlertAction(CREATE_CANDIDATE);
export const fetchCandidate = creatAlertAction(FETCH_CANDIDATE);
export const saveCandidate = creatAlertAction(SAVE_CANDIDATE);
