import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { useSessionActions } from 'store/session';

import { Button } from './styles';

const Item = ({
  name, path, type, length, closeMenu, pose,
}) => {
  const { push } = useHistory();
  const { logout } = useSessionActions();

  const handleClick = useCallback(() => {
    closeMenu();

    if (type === 'logout') {
      logout();
    }

    push(path);
  }, [push, path, type, logout, closeMenu]);

  return <Button type="button" pose={pose} onClick={handleClick} length={length}>{name}</Button>;
};

Item.propTypes = {
  name: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  length: PropTypes.number.isRequired,
  closeMenu: PropTypes.func.isRequired,
  pose: PropTypes.string.isRequired,
};

export default Item;
