import { createName } from 'store/utils';

import { FEATURE_NAME } from './consts';

const getName = createName(FEATURE_NAME);

export const UPDATE_EXPERIENCES = getName('UPDATE_EXPERIENCES');
export const RESET_EXPERIENCES = getName('RESET_EXPERIENCES');
export const UPDATE_EXPERIENCE = getName('UPDATE_EXPERIENCE');
export const DELETE_EXPERIENCE = getName('DELETE_EXPERIENCE');

export const FETCH_EXPERIENCES = getName('FETCH_EXPERIENCES');
export const FETCH_EXPERIENCE = getName('FETCH_EXPERIENCE');
export const CREATE_EXPERIENCE = getName('CREATE_EXPERIENCE');
export const SAVE_EXPERIENCE = getName('SAVE_EXPERIENCE');
export const REMOVE_EXPERIENCE = getName('REMOVE_EXPERIENCE');
