import styled from 'styled-components';

import { colors, fonts } from 'theme';

export const Container = styled.div`
  width: 100%;
  margin: 20px 0;
`;

export const Label = styled.p`
  ${fonts.regular}
`;

export const Text = styled.p`
  ${fonts.regular}
  color: ${colors.font.gray72};
`;
