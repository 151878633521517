import styled from 'styled-components';
import { metrics, colors, fonts } from 'theme';

import candidate from 'assets/png/candidate.png';
import institution from 'assets/png/institution.png';

export const HomePage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const Container = styled.div`
  position: relative;
  max-width: ${metrics.mobileWidth}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 50%;
  min-height: 310px;
  padding-bottom: 20px;
  &:first-child {
    padding-top: 0;
    border-bottom: 1px solid ${colors.black};
  }
`;

export const Title = styled.h1`
  ${fonts.title}
  font-size: 45.6px;
  text-align: center;
  margin: 20px 0;
`;

export const P = styled.p`
  ${fonts.regular}
  text-align: center;
  margin-bottom: 30px;
  padding: 0 20px;
`;

export const CandidateBackground = styled.div`
  width: 302px;
  height: 299px;
  position: absolute;
  top: -10px;
  right: 0;
  background: url(${candidate}) center/cover no-repeat;

  @media (max-width: ${metrics.maxWidth}px) {
    right: -15px;
    width: 280px;
    height: 277px;
  }
`;

export const InstitutionBackground = styled.div`
  width: 302px;
  height: 299px;
  position: absolute;
  bottom: -20px;
  left: 0;
  background: url(${institution}) center/cover no-repeat;

  @media (max-width: ${metrics.maxWidth}px) {
    bottom: -20px;
    left: -15px;
    width: 280px;
    height: 277px;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  position: relative;
`;

export const FAQButton = styled.button`
  width: 100%;
  max-width: ${metrics.mobileWidth}px;
  height: 60px;
  min-height: 60px;
  border-radius: 30px;
  background: ${colors.blue};
  box-shadow: ${metrics.shadow.button};
  margin-bottom: 30px;
  ${fonts.title}
  color: ${colors.white};
  transition: all .5s;
  z-index: 1;
  &:hover {
    background: ${colors.font.lightblue};
  }
`;
