import styled from 'styled-components';

import { metrics, colors, fonts } from 'theme';

export const Container = styled.div`
  width: 300px;
`;

export const Label = styled.p`
  ${fonts.regular}
  padding-bottom: 4px;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
`;

export const Input = styled.input`
  &:nth-child(1), &:nth-child(2) {
    width: 25%;
    margin-right: 5px;
  }
  &:nth-child(3) {
    width: 50%;
  }
  width: 100%;
  height: 44px;
  border-radius: 11px;
  box-shadow: ${({ error }) => (error ? metrics.shadow.inputError : metrics.shadow.input)};
  background-color: ${colors.white};
  text-align: center;
  ${fonts.regular}
`;
