import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { isEmpty } from 'ramda';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  NAME, ADDRESS, ZIP_CODE, CITY, PHONE,
  useInstitutionsActions, useAlerts,
} from 'store/institutions';
import BackPanel from 'components/BackPanel';
import Section from 'components/Section';
import Input from 'components/FormInput';
import Button from 'components/Button';
import Message from 'components/Message';
import Spinner from 'components/Spinner';

import { useInitialValues } from './hooks';
import { validationSchema, getError } from './validation';
import { Container, Wrapper, MessageWrap } from './styles';

const Institution = () => {
  const { push } = useHistory();
  const {
    extractId, success, loading, error, resetAlerts,
  } = useAlerts();
  const { saveInstitution } = useInstitutionsActions();
  const onSubmit = useCallback((values) => {
    extractId(saveInstitution(values));
  }, [extractId, saveInstitution]);
  const initialValues = useInitialValues();
  const { t } = useTranslation(['profile', 'form']);
  const handleSubmitClick = useCallback(() => resetAlerts(), [resetAlerts]);

  const {
    handleSubmit, getFieldProps, errors,
  } = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
    onSubmit,
  });
  const redirectToProfile = useCallback(() => push('/profile'), [push]);

  return (
    <Container onSubmit={handleSubmit}>
      <BackPanel title={t('Institution')} to="/profile" />
      <Section>
        <Input label={t('Name')} {...getFieldProps(NAME)} error={getError(errors[NAME])} />
      </Section>
      <Section>
        <Input label={t('Adresse')} {...getFieldProps(ADDRESS)} error={getError(errors[ADDRESS])} />
        <Input label={t('PLZ')} {...getFieldProps(ZIP_CODE)} error={getError(errors[ZIP_CODE])} />
        <Input label={t('Ort')} {...getFieldProps(CITY)} error={getError(errors[CITY])} />
      </Section>
      <Section>
        <Input label={t('Telefon')} {...getFieldProps(PHONE)} error={getError(errors[PHONE])} />
      </Section>
      <Wrapper>
        <Button type="button" onClick={redirectToProfile}>{t('form:Abbrechen')}</Button>
        <Button
          type="submit"
          color="blue"
          disabled={loading}
          onClick={handleSubmitClick}
        >
          {loading ? <Spinner size={40} /> : t('form:Speichern')}
        </Button>
        <MessageWrap>
          {(error || !isEmpty(errors)) && <Message error>{error || t('form:Wir haben einen Fehler in deinen Angaben gefunden. Bitte überprüfe deine Angaben.')}</Message>}
          {success && <Message success>{t('Dein Profil wurde aktualisiert.')}</Message>}
        </MessageWrap>
      </Wrapper>
    </Container>
  );
};

export default Institution;
