import styled from 'styled-components';

import { fonts, colors } from 'theme';

export const Container = styled.div`
  padding-top: 10px;
  margin-bottom: 40px;
`;

export const Text = styled.p`
  ${fonts.regular}
  color: ${colors.font.gray72};
`;
