import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Message from 'components/Message';

import { Container, Label, Input } from './styles';

const FormInput = ({
  label, className, error, placeholder, ...rest
}) => {
  const { t } = useTranslation('form');

  return (
    <Container className={className} error={error}>
      <Label>{label}</Label>
      <Input placeholder={placeholder || t('Noch nicht ausgefüllt')} error={error} {...rest} />
      <Message padding="7px 0 12px 0" error={error}>{error}</Message>
    </Container>
  );
};

FormInput.defaultProps = {
  label: '',
  className: '',
  error: '',
  placeholder: '',
};

FormInput.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  placeholder: PropTypes.string,
};

export default FormInput;
