import styled from 'styled-components';

import { metrics, fonts, colors } from 'theme';
import { Link } from 'react-router-dom';

export const Wrapper = styled.div`
  margin-top: 20px;
  border-top: 1px solid ${colors.gray};
  width: 100%;
  max-width: ${metrics.maxWidth}px;
`;

export const LinkWrapper = styled.div`
  margin: 8px 0 20px 0;
  display: flex;
  flex-direction:'row';
  justify-content: space-between;

  @media (max-width: 480px) {
    display: block;
  }
`;

export const Block = styled.div`
  margin-right: 50px;
`;

export const Title = styled.p`
  ${fonts.regularBold};
  color: ${colors.font.gray};
  font-size: 12px;
  line-height: 20px;
  padding-top: 8px;
  text-transform: uppercase;
`;

export const StyledLink = styled(Link)`
  ${fonts.regular};
  font-size: 12px;
  line-height: 25px;
  color: ${colors.font.blue};
  text-transform: uppercase;
  text-decoration: none;
  display: block;

  &:hover {
    color: ${colors.font.lightblue};
  }
`;

export const StyledExternalLink = styled.a`
  ${fonts.regular};
  font-size: 12px;
  line-height: 25px;
  color: ${colors.font.blue};
  text-transform: uppercase;
  text-decoration: none;
  display: block;

  &:hover {
    color: ${colors.font.lightblue};
  }
`;

export const Copyright = styled.p`
  margin-top: 20px;
  border-top: 1px solid ${colors.gray};
  width: 100%;
  ${fonts.regularBold};
  color: ${colors.font.gray};
  font-size: 12px;
  padding-top: 8px;
  padding-bottom: 8px;
`;
