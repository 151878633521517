import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Experience from './Experience';
import { handleList } from './utils';

const ExperienceList = ({ list, interactive }) => {
  const { t } = useTranslation('form');
  const experiences = useMemo(() => handleList(t)(list), [t, list]);

  return (
    <>
      {experiences.map((item) => (
        <Experience
          key={item.id}
          {...item}
          interactive={interactive}
        />
      ))}
    </>
  );
};

ExperienceList.defaultProps = {
  interactive: false,
  list: [],
};

ExperienceList.propTypes = {
  interactive: PropTypes.bool,
  list: PropTypes.arrayOf(PropTypes.object),
};

export default ExperienceList;
