import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import GlobalStyles from 'theme/global';
import Layout from 'components/Layout';
import Home from 'components/Home';
import Register from 'components/Register';
import Profile from 'components/Profile';
import Personal from 'components/Personal';
import Institution from 'components/Institution';
import Skills from 'components/Skills';
import Availability from 'components/Availability';
import About from 'components/About';
import Imprint from 'components/Imprint';
import Privacy from 'components/Privacy';
import Searches from 'components/Searches';
import Search from 'components/Search';
import Experiences from 'components/Experiences';
import Experience from 'components/Experience';
import Recommendations from 'components/Recommendations';
import Favorites from 'components/Favorites';
import {
  USER, INSTITUTION, CANDIDATE, EXPERIENCE, SEARCH, MATCH,
} from 'permissions';

import InitApp from './InitApp';
import PrivateRoute from './PrivateRoute';

const Root = () => (
  <>
    <GlobalStyles />
    <Layout>
      <InitApp>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/register" component={Register} />
          <Route exact path="/about" component={About} />
          <Route exact path="/imprint" component={Imprint} />
          <Route exact path="/privacy" component={Privacy} />
          <PrivateRoute exact sources={[USER]} path="/profile" component={Profile} />
          <PrivateRoute exact sources={[USER]} path="/personal" component={Personal} />
          <PrivateRoute exact sources={[INSTITUTION]} path="/institution" component={Institution} />
          <PrivateRoute exact sources={[CANDIDATE]} path="/skills" component={Skills} />
          <PrivateRoute exact sources={[CANDIDATE]} path="/availability" component={Availability} />
          <PrivateRoute exact sources={[EXPERIENCE]} path="/experiences" component={Experiences} />
          <PrivateRoute exact sources={[EXPERIENCE]} path="/experiences/:id" component={Experience} />
          <PrivateRoute exact sources={[SEARCH]} path="/searches" component={Searches} />
          <PrivateRoute exact sources={[SEARCH]} path="/searches/:id" component={Search} />
          <PrivateRoute exact sources={[SEARCH, MATCH]} path="/recommendations/:id" component={Recommendations} />
          <PrivateRoute exact sources={[INSTITUTION, MATCH]} path="/favorites" component={Favorites} />
          <Redirect to="/" />
        </Switch>
      </InitApp>
    </Layout>
  </>
);

export default Root;
