import * as session from './session';
import * as candidates from './candidates';
import * as experiences from './experiences';
import * as searches from './searches';
import * as institutions from './institutions';
import * as matches from './matches';

export default {
  ...session,
  ...candidates,
  ...experiences,
  ...searches,
  ...institutions,
  ...matches,
};
