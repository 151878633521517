import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { usePermission, CANDIDATE, INSTITUTION } from 'permissions';
import { useContent, MENU } from 'content';

import Item from './Item';
import { Container } from './styles';

const Menu = ({ open, closeMenu }) => {
  const can = usePermission();
  const { unAuth, candidate, institution } = useContent(MENU);

  const menuItems = useMemo(() => {
    if (can.read(CANDIDATE) && can.update(CANDIDATE)) return candidate;
    if (can.read(INSTITUTION) && can.update(INSTITUTION)) return institution;

    return unAuth;
  }, [unAuth, candidate, institution, can]);

  const pose = useMemo(() => (open ? 'open' : 'close'), [open]);

  return (
    <Container pose={pose} initialPose="close">
      {menuItems.map(({ name, path, type }) => (
        <Item
          pose={pose}
          key={name}
          name={name}
          path={path}
          type={type}
          length={menuItems.length}
          closeMenu={closeMenu}
        />
      ))}
    </Container>
  );
};

Menu.propTypes = {
  open: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

export default Menu;
