export const DE = 'de';
export const FR = 'fr';

export const DEFAULT_LANGUAGE = DE;

export const LANG_LIST = [DE, FR];

export const LOCALE_LIST = ['home', 'about', 'privacy', 'availability', 'skill', 'search', 'register', 'profile', 'footer', 'form', 'errors', 'experience'];

export const LANG_LABELS = [
  {
    id: DE,
    name: 'Deutsch',
  },
  {
    id: FR,
    name: 'Français',
  },
];
