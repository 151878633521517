import React from 'react';
import OtpInput from 'react-otp-input';
import PropTypes from 'prop-types';

import { Container } from './styles';

const MultiInput = ({
  value, onChange, error, disabled,
}) => (
  <Container error={error}>
    <OtpInput
      value={value}
      onChange={onChange}
      numInputs={5}
      isDisabled={disabled}
    />
  </Container>
);

MultiInput.defaultProps = {
  error: '',
  disabled: false,
};

MultiInput.propTypes = {
  value: PropTypes.PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MultiInput;
