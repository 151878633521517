export const FEATURE_NAME = 'searches';

export const ID = '_id';
export const LIST = 'list';
export const RECOMMENDATIONS = 'recommendations';
export const NEW_SEARCH = 'new_search';

export const NAME = 'name';
export const SKILLS = 'skills';
export const EDUCATION = 'education';
export const EXPERIENCE = 'experience';
export const REGIONS = 'regions';
export const AVAILABILITIES = 'availabilites';
export const INSTITUTION = 'institution';

export const CANDIDATE = 'candidate';
export const EXPERIENCES = 'experiences';
