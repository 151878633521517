import React from 'react';
import PropTypes from 'prop-types';

import { Container, Title, Wrapper } from './styles';

const Section = ({ title, children, withoutBorder }) => (
  <Container withoutBorder={withoutBorder}>
    <Title>{title}</Title>
    <Wrapper>{children}</Wrapper>
  </Container>
);

Section.defaultProps = {
  title: '',
  children: null,
  withoutBorder: false,
};

Section.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(
      PropTypes.element,
    ),
  ]),
  withoutBorder: PropTypes.bool,
};

export default Section;
