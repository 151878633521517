import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="30" height="29" viewBox="0 0 30 29">
    <g fill="none" fillRule="evenodd" stroke="#727272">
      <path d="M2 0L2 41" transform="translate(-1 -2) rotate(45 13.828 35.4)" />
      <path d="M2 0L2 41" transform="translate(-1 -2) scale(-1 1) rotate(45 -2.172 -3.228)" />
    </g>
  </svg>
);
