import colors from './colors';

const regular = `
  font-family: "HKGrotesk-Medium", sans-serif;
  font-size: 19px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.25px;
  color: ${colors.black};
`;

export default {
  menu: `
    font-family: "HKGrotesk-SemiBold", sans-serif;
    font-size: 55px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.5px;
    color: ${colors.font.blue};
  `,
  title: `
    font-family: "HKGrotesk-SemiBold", sans-serif;
    font-size: 27.5px;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.36px;
    color: ${colors.black};
  `,
  regular,
  regularBold: `
    ${regular}
    font-family: "HKGrotesk-SemiBold", sans-serif;
  `,
  regularSmall: `
    ${regular}
    font-size: 14px;
    letter-spacing: normal;
  `,
};
