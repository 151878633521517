import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'assets/svg/Cross';

import { Button } from './styles';

const AnimateCross = ({ isOpen, onClick, className }) => (
  <Button pose={isOpen ? 'open' : 'close'} onClick={onClick} className={className}>
    <Icon />
  </Button>
);

AnimateCross.defaultProps = {
  isOpen: false,
  onClick: () => null,
  className: '',
};

AnimateCross.propTypes = {
  isOpen: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default AnimateCross;
