import { object, string } from 'yup';

import i18n from 'locale';
import {
  ID, NAME, ADDRESS, ZIP_CODE, CITY, PHONE,
} from 'store/institutions';

export const validationSchema = object().shape({
  [ID]: string().required('Oops, ein Fehler ist aufgetreten. Hilf uns, dein Benutzererlebnis zu verbessern und sende uns einen Fehlerbericht.'),
  [NAME]: string().required('Name fehlt'),
  [ADDRESS]: string().required('Adresse fehlt'),
  [ZIP_CODE]: string().required('Postleitzahl fehlt'),
  [CITY]: string().required('Stadt fehlt'),
  [PHONE]: string().required('Telefon fehlt'),
});

export const getError = (error) => error && i18n.t(`errors:${error}`);
