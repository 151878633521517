export const FEATURE_NAME = 'experiences';

export const ID = '_id';
export const NEW_EXPERIENCE = 'new_experience';

export const EMPLOYER = 'employer';
export const POSITION = 'position';
export const START = 'start';
export const END = 'end';
export const UNTIL_TODAY = 'untilToday';
