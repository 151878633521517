import { prop, omit } from 'ramda';

import { ID } from 'store/institutions';

import { wrapperRequest } from './utils';
import { API_URL, PUT } from './consts';

export const getInstitution = (institutionId) => wrapperRequest({
  url: `${API_URL}/institutions/${institutionId}`,
});

export const putInstitution = (body = {}) => wrapperRequest({
  url: `${API_URL}/institutions/${prop(ID, body)}`,
  options: { method: PUT, body: omit([ID], body) },
});

export const getFavorites = (institutionId) => wrapperRequest({
  url: `${API_URL}/institutions/${institutionId}/favorites`,
});
