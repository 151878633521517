import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/AnimateCross';

import {
  Container, Title, TitleWrapper, Content,
} from './styles';

const Accordion = ({
  title, children, className, align,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  return (
    <Container className={className}>
      <Title onClick={handleClick} align={align}>
        <TitleWrapper>{title}</TitleWrapper>
        <Icon isOpen={isOpen} />
      </Title>
      <Content pose={isOpen ? 'open' : 'close'}>
        {children}
      </Content>
    </Container>
  );
};

Accordion.defaultProps = {
  className: '',
  align: 'center',
};

Accordion.propTypes = {
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  className: PropTypes.string,
  align: PropTypes.string,
};

export default Accordion;
