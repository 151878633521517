import { prop, omit } from 'ramda';

import { ID } from 'store/experiences';

import { wrapperRequest } from './utils';
import {
  API_URL, POST, PUT, DELETE,
} from './consts';

export const postSearch = (body = {}) => wrapperRequest({
  url: `${API_URL}/searches`,
  options: { method: POST, body: omit([ID], body) },
});

export const getSearches = () => wrapperRequest({
  url: `${API_URL}/searches`,
});

export const getSearch = (searchId) => wrapperRequest({
  url: `${API_URL}/searches/${searchId}`,
});

export const putSearch = (body = {}) => wrapperRequest({
  url: `${API_URL}/searches/${prop(ID, body)}`,
  options: { method: PUT, body: omit([ID], body) },
});

export const deleteSearch = (searchId) => wrapperRequest({
  url: `${API_URL}/searches/${searchId}`,
  options: { method: DELETE },
});

export const postRecommendations = (searchId) => wrapperRequest({
  url: `${API_URL}/searches/${searchId}/execute`,
  options: { method: POST },
});
