import { useMemo } from 'react';

import {
  ID, NAME, ADDRESS, ZIP_CODE, CITY, PHONE, useInstitution,
} from 'store/institutions';

const fields = [
  ID, NAME, ADDRESS, ZIP_CODE, CITY, PHONE,
];

export const useInitialValues = () => {
  const institution = useInstitution() || {};

  return useMemo(() => fields.reduce((acc, field) => {
    acc[field] = institution[field] || '';

    return acc;
  }, {}), [institution]);
};
