/* eslint-disable react/no-array-index-key */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ifElse, is } from 'ramda';

import Message from 'components/Message';

import {
  Container, Label, Wrapper, IconWrapper, Triangle,
} from './styles';

const Select = ({
  label, options, value, error, required, className, ...rest
}) => {
  const { t } = useTranslation('form');

  return (
    <Container error={error} className={className}>
      <Label>{label}</Label>
      <Wrapper error={error}>
        <select value={value} {...rest}>
          <option value="" disabled>{t('Bitte auswählen')}</option>
          {required || (value && <option value="">{t('Keine Angabe')}</option>)}
          {options.map((item, i) => ifElse(
            is(Object),
            () => <option key={i} value={item.id}>{item.name}</option>,
            () => <option key={i} value={item}>{item}</option>,
          )(item))}
        </select>
        <IconWrapper>
          <Triangle top />
          <Triangle />
        </IconWrapper>
      </Wrapper>
      {error && <Message padding="7px 0 12px 0" error>{error}</Message>}
    </Container>
  );
};

Select.defaultProps = {
  label: '',
  value: '',
  error: '',
  className: '',
  required: false,
};

Select.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
    ]).isRequired,
  ).isRequired,
  value: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  className: PropTypes.string,
};

export default Select;
