import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import { SecondLayer, ThirdLayer } from './styles';
import CheckBoxButton from './CheckBoxButton';

const MultiLayerSelection = ({ data, selected, onUpdate }) => {
  const isActive = useCallback((key) => selected.includes(key), [selected]);

  const hasActiveChildren = useCallback((parent) => {
    const children = parent.values.map((v) => v.key);
    const result = children.filter((c) => selected.includes(c));
    return Boolean(result.length);
  }, [selected]);

  const onSelect = useCallback((item) => {
    const flattenKeys = (arr) => {
      const result = [];
      arr.forEach((a) => {
        result.push(a.key);
        if (a.values.length) result.push(...flattenKeys(a.values));
      });

      return result;
    };

    if (isActive(item.key)) {
      const keys = [item.key, ...flattenKeys(item.values)];
      onUpdate(selected.filter((selectedItem) => !keys.includes(selectedItem)));
    } else onUpdate([...selected, item.key]);
  }, [isActive, onUpdate, selected]);

  return (
    <>
      {data.map((parent) => (
        <div key={parent.key}>
          <CheckBoxButton
            label={parent.name}
            isActive={isActive(parent.key)}
            hasActiveChildren={hasActiveChildren(parent)}
            onClick={() => onSelect(parent)}
          />
          <SecondLayer pose={isActive(parent.key) ? 'open' : 'close'} initialPose="close">
            {parent.values.map((child) => (
              <div key={child.key}>
                <CheckBoxButton
                  label={child.name}
                  level={2}
                  isActive={isActive(child.key)}
                  hasActiveChildren={hasActiveChildren(child)}
                  onClick={() => onSelect(child)}
                />
                {isActive(child.key) && Boolean(child.values.length) && (
                <ThirdLayer pose={isActive(child.key) ? 'open' : 'close'} initialPose="close">
                  {child.values.map((item) => (
                    <CheckBoxButton
                      key={item.key}
                      label={item.name}
                      level={3}
                      isActive={isActive(item.key)}
                      hasActiveChildren={false}
                      onClick={() => onSelect(item)}
                    />
                  ))}
                </ThirdLayer>
                )}
              </div>
            ))}
          </SecondLayer>
        </div>
      ))}
    </>
  );
};

MultiLayerSelection.propTypes = {
  selected: PropTypes.instanceOf(Array).isRequired,
  onUpdate: PropTypes.func.isRequired,
  data: PropTypes.instanceOf(Array).isRequired,
};

export default MultiLayerSelection;
