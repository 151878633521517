import { useCallback } from 'react';
import { pipe, path } from 'ramda';

import { useAsyncState } from 'utils/useAsyncState';
import { wrapActions, wrapSelectorWithArg } from 'store/utils';

import { META, TASK_ID } from './consts';
import * as actions from './actions';
import {
  getSuccess,
  getError,
  getLoading,
} from './selectors';

export const useAlertsActions = wrapActions({ ...actions });

export const useSuccess = wrapSelectorWithArg(getSuccess);
export const useError = wrapSelectorWithArg(getError);
export const useLoading = wrapSelectorWithArg(getLoading);

export const useAlerts = () => {
  const [id, setId] = useAsyncState();

  const success = useSuccess(id);
  const error = useError(id);
  const loading = useLoading(id);
  const { setCancel } = useAlertsActions();

  const extractId = useCallback(pipe(
    path([META, TASK_ID]),
    setId,
  ), [setId]);

  const resetAlerts = useCallback(() => {
    if (id) {
      setCancel(id);
      setId();
    }
  }, [id, setCancel, setId]);

  return {
    id,
    setId,
    extractId,
    success: id && success,
    error: id && error,
    loading: id && loading,
    resetAlerts,
  };
};
