import { object, string } from 'yup';

import i18n from 'locale';
import {
  FIRST_NAME,
  LAST_NAME,
  EMAIL,
  LANGUAGE,
} from 'store/session';

export const validationSchema = object().shape({
  [FIRST_NAME]: string().required('Vorname fehlt'),
  [LAST_NAME]: string().required('Nachname fehlt'),
  [EMAIL]: string().required('E-Mail-Adresse fehlt').email('E-Mail-Adresse ist ungültig'),
  [LANGUAGE]: string().required('Wähle eine Sprache aus'),
  isDateError: string().notRequired(),
});

export const getError = (error) => error && i18n.t(`errors:${error}`);
