import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { handleActions } from 'redux-actions';
import {
  prop, pipe, map, reduce, cond, not, is, anyPass, T, omit,
} from 'ramda';

export const createName = (feature) => (name) => `${feature}/${name}`;

export const wrapActions = (actions) => () => {
  const dispatch = useDispatch();

  return useMemo(
    () => map(
      (action) => pipe(
        action,
        dispatch,
      ),
      actions,
    ),
    [dispatch],
  );
};

export const wrapSelector = (selector) => (shallowEqual) => (
  useSelector(selector, shallowEqual)
);

export const wrapSelectorWithArg = (selector) => (arg, shallowEqual) => (
  useSelector(selector(arg), shallowEqual)
);

export const wrapMultiSelector = (
  selectorByItem, selectorForAllItem,
) => (
  arg, shallowEqual,
) => cond([
  [pipe(Boolean, not), () => useSelector(selectorForAllItem, shallowEqual)],
  [is(String), ($) => useSelector(selectorByItem($), shallowEqual)],
  [anyPass([is(Array), is(Object)]), map(($) => useSelector(selectorByItem($), shallowEqual))],
  [T, () => useSelector(selectorForAllItem, shallowEqual)],
])(arg);

export const normalizeArray = (field, arr) => reduce(
  (acc, item) => {
    acc[prop(field, item)] = item;

    return acc;
  },
  {},
  arr,
);

export const reducerTemplate = ({
  id,
  list,
  item,
  deleteItem,
  reset,
}, init) => handleActions({
  [list]: (_, { payload }) => normalizeArray(id, payload),
  [item]: (state, { payload }) => ({
    ...state,
    [prop(id, payload)]: payload,
  }),
  [deleteItem]: (state, { payload }) => ({
    ...omit([prop(id, payload)], state),
  }),
  [reset]: () => init,
}, init);
