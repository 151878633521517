import React from 'react';

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33">
    <g fill="none" fillRule="evenodd">
      <path d="M25.731 13.052l-6.953-6.95 4.465-4.735c.458-.459 1.13-.53 1.498-.162l5.593 5.904c.37.37.299 1.042-.16 1.5l-4.443 4.443z" transform="translate(1)" />
      <path stroke="#727272" d="M25.731 13.052l-6.953-6.95 4.465-4.735c.458-.459 1.13-.53 1.498-.162l5.593 5.904c.37.37.299 1.042-.16 1.5l-4.443 4.443z" transform="translate(1)" />
      <path d="M8.21 30.572L0.137 31.692 1.257 23.621 16.328 8.55 23.28 15.503z" transform="translate(1)" />
      <path stroke="#727272" d="M8.21 30.572L0.137 31.692 1.257 23.621 16.328 8.55 23.28 15.503z" transform="translate(1)" />
    </g>
  </svg>
);
