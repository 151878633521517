import styled from 'styled-components';

import { metrics } from 'theme';

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 91px;
  height: 91px;
  border-radius: 50%;
  background-image: ${({ url }) => (url ? `url('${url}')` : 'none')};
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  box-shadow: ${metrics.shadow.input};
`;

export const Input = styled.input`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 50%;
  cursor: pointer;
`;
