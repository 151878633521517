import {
  prop, cond, is, pipe, not, has,
} from 'ramda';

import { GET, TOKEN, APPLICATION_TOKEN } from './consts';

export const getToken = () => prop(TOKEN, window.localStorage);

const requestHeaders = () => ({
  'Content-Type': 'application/json',
  'api-key': APPLICATION_TOKEN,
  AUTHORIZATION: `Bearer ${getToken()}`,
});

const handleOptions = (data = {}) => {
  const {
    method = GET,
    headers = requestHeaders(),
    body,
    file,
  } = data;

  const options = {
    method,
    headers,
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  if (file) {
    options.body = file;
  }

  return options;
};

const handleError = (error = {}, rej) => {
  const message = cond([
    [is(String), ($) => $],
    [pipe(is(Object), not), () => ''],
    [has('message'), prop('message')],
    [has('error'), prop('error')],
  ])(error);

  return rej(new Error(message));
};

export const wrapperRequest = ({ url, options }) => new Promise((resolve, reject) => {
  fetch(url, handleOptions(options)).then(
    (response) => {
      if (response.status === 401) {
        window.localStorage.removeItem(TOKEN);
        window.location.replace('/');

        return response.json().then((err) => handleError(err, reject));
      }

      if (response.status < 200 || response.status >= 300) {
        return response.json().then((err) => handleError(err, reject));
      }

      return resolve(response.json());
    },
    () => {
      const error = new Error('Ouups, wir konnten keine Verbindung zum Server herstellen. Überprüfe deine Internetverbindung.');
      error.type = 'connection';

      return reject(error);
    },
  );
});
