import styled from 'styled-components';

import { metrics, colors, fonts } from 'theme';

export default styled.button`
  width: 100%;
  height: 60px;
  border-radius: 30px;
  background: ${colors.white};
  box-shadow: ${metrics.shadow.button};
  ${fonts.title}
  color: ${({ color }) => (color === 'blue' ? colors.font.blue : colors.black)};
  transition: all .5s;
  &:disabled {
    color: ${colors.font.gray};
  }
`;
