import React, { useCallback } from 'react';
import { useFormik } from 'formik';
import { omit, isEmpty } from 'ramda';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  FIRST_NAME,
  LAST_NAME,
  ADDRESS,
  ZIP_CODE,
  CITY,
  MOBILE,
  BIRTH,
  LANGUAGE,
  useSessionActions,
  useAlerts,
} from 'store/session';
import BackPanel from 'components/BackPanel';
import Section from 'components/Section';
import Input from 'components/FormInput';
import Button from 'components/Button';
import DateInput from 'components/DateInput';
import Message from 'components/Message';
import Spinner from 'components/Spinner';
import Select from 'components/Select';
import { LANG_LABELS } from 'locale/consts';
import { usePermission, CANDIDATE } from 'permissions';

import { useInitialValues } from './hooks';
import { validationSchema, getError } from './validation';
import { Container, Wrapper, MessageWrap } from './styles';


const Personal = () => {
  const can = usePermission();
  const { push } = useHistory();
  const { t, i18n } = useTranslation(['profile', 'form']);
  const {
    extractId, success, loading, error, resetAlerts,
  } = useAlerts();
  const { saveUser } = useSessionActions();
  const onSubmit = useCallback((values) => {
    const lang = values[LANGUAGE];

    extractId(saveUser(omit(['isDateError'], values)));

    if (lang !== i18n.language) {
      i18n.changeLanguage(values[LANGUAGE]);
    }
  }, [extractId, saveUser, i18n]);
  const initialValues = useInitialValues();
  const handleSubmitClick = useCallback(() => resetAlerts(), [resetAlerts]);

  const {
    values, handleSubmit, getFieldProps, setFieldValue, errors,
  } = useFormik({
    initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validateOnMount: false,
    validationSchema,
    onSubmit,
  });
  const redirectToProfile = useCallback(() => push('/profile'), [push]);

  const handleChangeDateInput = useCallback((date) => setFieldValue(BIRTH, date), [setFieldValue]);
  const handleErrorDateInput = useCallback((err) => setFieldValue('isDateError', err || ''), [setFieldValue]);

  return (
    <Container onSubmit={handleSubmit}>
      <BackPanel title={t('Persönliche Angaben')} to="/profile" />
      <Section>
        <Input label={t('Vorname')} {...getFieldProps(FIRST_NAME)} error={getError(errors[FIRST_NAME])} />
        <Input label={t('Nachname')} {...getFieldProps(LAST_NAME)} error={getError(errors[LAST_NAME])} />
      </Section>
      {can.update(CANDIDATE) && (
        <>
          <Section>
            <Input label={t('Adresse')} {...getFieldProps(ADDRESS)} />
            <Input label={t('PLZ')} {...getFieldProps(ZIP_CODE)} />
            <Input label={t('Ort')} {...getFieldProps(CITY)} />
          </Section>
          <Section>
            <Input label={t('Mobile')} {...getFieldProps(MOBILE)} />
            <DateInput
              key={values[BIRTH]}
              label={t('Geburtsdatum')}
              value={values[BIRTH]}
              onChange={handleChangeDateInput}
              onError={handleErrorDateInput}
            />
          </Section>
        </>
      )}
      <Section>
        <Select
          label={t('Sprache')}
          options={LANG_LABELS}
          {...getFieldProps(LANGUAGE)}
          error={getError(errors[LANGUAGE])}
          required
        />
      </Section>
      <Wrapper>
        <Button type="button" onClick={redirectToProfile}>{t('form:Abbrechen')}</Button>
        <Button
          type="submit"
          color="blue"
          disabled={loading}
          onClick={handleSubmitClick}
        >
          {loading ? <Spinner size={40} /> : t('form:Speichern')}
        </Button>
        <MessageWrap>
          {(error || !isEmpty(errors)) && <Message error>{error || t('form:Wir haben einen Fehler in deinen Angaben gefunden. Bitte überprüfe deine Angaben.')}</Message>}
          {success && (
          <Message success>{t('Dein Profil wurde aktualisiert.')}</Message>
          )}
        </MessageWrap>
      </Wrapper>
    </Container>
  );
};

export default Personal;
