import styled from 'styled-components';

import { colors, fonts, metrics } from 'theme';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: ${({ interactive }) => (interactive ? 100 : 70)}px;
  border-radius: 11px;
  margin-bottom: 10px;
  box-shadow: ${({ interactive }) => (interactive ? metrics.shadow.input : 'none')};
  padding: ${({ interactive }) => (interactive ? '10px 8px 10px 16px' : 0)};
`;

export const Text = styled.p`
  ${fonts.regular}
  color: ${({ interactive }) => (interactive ? colors.black : colors.font.gray72)};
`;

export const Button = styled.button`
  width: 32px;
  height: 32px;
`;

export const Texts = styled.div`
  flex: 1 1 auto;
  margin-right: 16px;
  cursor: ${({ interactive }) => (interactive ? 'pointer' : 'auto')};
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const SpinnerWrap = styled.div` 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  opacity: .8;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
`;
