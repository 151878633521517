import styled from 'styled-components';

import { fonts, metrics, colors } from 'theme';

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  box-shadow: ${metrics.shadow.input};
  border-radius: 11px;
  min-height: 120px;
`;

export const Texts = styled.div`
  flex: 1 1 auto;
  margin: 10px 8px 10px 16px;
  cursor: pointer;
`;

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px 8px 10px 16px;
`;

export const Title = styled.p`${fonts.regularBold}`;

export const Text = styled.p`${fonts.regularSmall}`;

export const Button = styled.button`
  width: 32px;
  height: 32px;
`;

export const SpinnerWrap = styled.div` 
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: ${({ show }) => (show ? 'flex' : 'none')};
  opacity: .8;
  justify-content: center;
  align-items: center;
  background: ${colors.white};
`;
