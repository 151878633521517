import styled from 'styled-components';

import { colors, fonts, metrics } from 'theme';

export const Container = styled.div`
  width: 100%;
  margin-bottom: 16px;
  &>div {
    width: 100%;
    min-width: 300px;
    justify-content: space-between;
  }
  & input {
    width: 55px !important;
    height: 60px;
    border: none;
    outline: none;
    border-radius: 20px;
    box-shadow: ${({ error }) => (error ? metrics.shadow.inputError : metrics.shadow.input)};
    background-color: ${colors.white};
    padding: 10px;
    ${fonts.regular}
  }
`;
