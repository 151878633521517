import styled from 'styled-components';

import { fonts, colors } from 'theme';

const { gray, blue, lightblue } = colors.font;

export const Lang = styled.button`
  ${fonts.regular};
  font-size: 12px;
  line-height: 25px;
  color: ${({ active }) => (active ? gray : blue)};
  text-transform: uppercase;
  text-decoration: none;
  display: block;
  &:hover {
    color: ${({ active }) => (active ? gray : lightblue)};
  }
`;
