/* eslint-disable react/no-array-index-key */
import {
  useState, useCallback, useRef, useMemo,
} from 'react';
import {
  props, cond, equals, T,
} from 'ramda';

import { ID as USER_ID } from 'store/session';
import {
  ID, STATUS, useMatchesActions, useAlerts,
} from 'store/matches';
import { mergeAlerts } from 'store/alerts';
import { CANDIDATE_STATUSES, ACTIVE, INACTIVE } from 'utils/consts';
import { colors } from 'theme';
import i18n from 'locale';

export const useSubmitActions = (userId, match = {}) => {
  const loadingAction = useRef();
  const [matchId, status = 0] = props([ID, STATUS], match);
  const [open, setOpen] = useState(false);
  const createAlerts = useAlerts();
  const saveAlerts = useAlerts();
  const { loading, error } = mergeAlerts([createAlerts, saveAlerts]);

  const changeConfirmMenu = useCallback(() => setOpen(!open), [open]);

  const statusLabel = useMemo(() => cond([
    [equals(CANDIDATE_STATUSES.ACTIVE), () => i18n.t('search:Markiert')],
    [equals(CANDIDATE_STATUSES.INACTIVE), () => i18n.t('search:Kontaktiert')],
    [T, () => ''],
  ])(status), [status]);
  const statusColor = useMemo(() => cond([
    [equals(CANDIDATE_STATUSES.ACTIVE), () => colors.yellow],
    [equals(CANDIDATE_STATUSES.INACTIVE), () => colors.green],
    [T, () => 'none'],
  ])(status), [status]);

  const { createMatch, saveMatch } = useMatchesActions();
  const updateStatus = useCallback((val) => {
    if (matchId) {
      saveAlerts.extractId(saveMatch({ ...match, [STATUS]: val }));
    } else {
      createAlerts.extractId(createMatch({ user: { [USER_ID]: userId }, [STATUS]: val }));
    }

    setOpen(false);
  }, [matchId, userId, saveAlerts, saveMatch, createAlerts, createMatch, match]);

  const requestMarket = useCallback(() => {
    updateStatus(CANDIDATE_STATUSES[ACTIVE]);
    loadingAction.current = CANDIDATE_STATUSES[ACTIVE];
  }, [updateStatus]);

  const requestConnect = useCallback(() => {
    updateStatus(CANDIDATE_STATUSES[INACTIVE]);
    loadingAction.current = CANDIDATE_STATUSES[INACTIVE];
  }, [updateStatus]);

  if (!loading) {
    loadingAction.current = null;
  }

  return {
    confirmMenu: open,
    changeConfirmMenu,
    statusLabel,
    statusColor,
    isActive: status === CANDIDATE_STATUSES[ACTIVE],
    markerName: status === CANDIDATE_STATUSES[ACTIVE] ? i18n.t('search:Helfer*in markiert') : i18n.t('search:Helfer*in markieren'),
    requestMarket,
    markerLoading: loadingAction.current === CANDIDATE_STATUSES[ACTIVE],
    isInactive: status === CANDIDATE_STATUSES[INACTIVE],
    connectName: status === CANDIDATE_STATUSES[INACTIVE] ? i18n.t('search:Helfer*in kontaktiert') : i18n.t('search:Helfer*in kontaktieren'),
    requestConnect,
    connectLoading: loadingAction.current === CANDIDATE_STATUSES[INACTIVE],
    loading,
    error,
  };
};
